import dayjs from 'dayjs';
import { tabletPackages, brands } from '../Database/Drugs';

function getDrugInfo(brandId) {

    const brand = brands.find(b => b.brandId === brandId);
    if (!brand) {
        throw new Error(`Merke med ID ${brandId} ikke funnet.`);
    }
    const brandTablets = tabletPackages.filter(tp => tp.brandId === brandId);
    const strengths = [...new Set(brandTablets.map(tp => tp.strength))];
    const divisible = brandTablets.some(tp => tp.divisible === 'yes') ? 'yes' : 'no';

    return {
        brandId: brand.brandId,
        brandName: brand.brandName,
        drugType: brand.drugType,
        genericName: brand.genericName,
        timesPerDay: brand.timesPerDay,
        strengths: strengths,
        divisible: divisible
    };
}

// function getStrengthsAndPackagesByGenericName(genericName) {

//     const strengthsSet = new Set();
//     const strengthsWithBrands = {};
//     const packagesByStrength = {};

//     const brandsOfGenericName = brands.filter(b => b.genericName === genericName);

//     for (let brand of brandsOfGenericName) {
//         const brandTablets = tabletPackages.filter(tp => tp.brandId === brand.brandId);

//         for (let tablet of brandTablets) {
          
//             const strength = tablet.strength;
//             strengthsSet.add(strength);

//             if (!strengthsWithBrands[strength]) {
//                 strengthsWithBrands[strength] = new Set();
//             }
//             strengthsWithBrands[strength].add(brand.brandName);

//             if (!packagesByStrength[strength]) {
//                 packagesByStrength[strength] = [];
//             }
            
//             packagesByStrength[strength].push({
//                 brandId: brand.brandId,
//                 brandName: brand.brandName,
//                 packageSize: tablet.packageSize,
//                 divisible: tablet.divisible,
//                 halvingLine: tablet.halvingLine
//             });

//         }
//     }

//     const strengths = Array.from(strengthsSet).sort((a, b) => b - a);

//     return {
//         strengths: strengths,
//         strengthsWithBrands: strengthsWithBrands,
//         packagesByStrength: packagesByStrength
//     };
// }

function getStrengthsAndPackagesByBrand(brand) {

  const strengthsSet = new Set();
  const strengthsWithBrands = {};
  const packagesByStrength = {};

  const brandTablets = tabletPackages.filter(tp => tp.brandId === brand.brandId);

  for (let tablet of brandTablets) {

      const strength = tablet.strength;
      strengthsSet.add(strength);

      if (!strengthsWithBrands[strength]) {
          strengthsWithBrands[strength] = new Set();
      }
      strengthsWithBrands[strength].add(brand.brandName);

      if (!packagesByStrength[strength]) {
          packagesByStrength[strength] = [];
      }
      
      packagesByStrength[strength].push({
          brandId: brand.brandId,
          brandName: brand.brandName,
          packageSize: tablet.packageSize,
          divisible: tablet.divisible,
          halvingLine: tablet.halvingLine
      });

  }

  const strengths = Array.from(strengthsSet).sort((a, b) => b - a);

  return {
      strengths: strengths,
      strengthsWithBrands: strengthsWithBrands,
      packagesByStrength: packagesByStrength
  };
}

export function getReductionParameters(drugCategory, durationOfUse) {
    let durationIndex;
    if (durationOfUse === '1-3 måneder') durationIndex = 1;
    else if (durationOfUse === '3-12 måneder') durationIndex = 2;
    else if (durationOfUse === 'Mer enn 1 år') durationIndex = 3;
    else throw new Error('Ugyldig varighet av behandling valgt.');

    const paramsByCategory = {
        'Benzodiazepiner': {
            1: { totalWeeks: 4, percentageMin: 25, percentageMax: 30, daysPerStep: 3 },
            2: { totalWeeks: 8, percentageMin: 25, percentageMax: 30, daysPerStep: 5 },
            3: { totalWeeks: 15, percentageMin: 20, percentageMax: 25, daysPerStep: 7 },
        },
        'Opioder': {
            1: { totalWeeks: 2, percentageMin: 30, percentageMax: 30, daysPerStep: 2 },
            2: { totalWeeks: 4, percentageMin: 25, percentageMax: 30, daysPerStep: 3 },
            3: { totalWeeks: 12, percentageMin: 30, percentageMax: 30, daysPerStep: 7 },
        },
        'Svake opioider': {
            1: { totalWeeks: 2, percentageMin: 30, percentageMax: 30, daysPerStep: 2 },
            2: { totalWeeks: 4, percentageMin: 25, percentageMax: 30, daysPerStep: 3 },
            3: { totalWeeks: 12, percentageMin: 30, percentageMax: 30, daysPerStep: 7 },
        },
    };

    if (!paramsByCategory[drugCategory]) {
        throw new Error(`Ingen reduksjonsparametere funnet for legemiddelkategori: ${drugCategory}`);
    }

    if (!paramsByCategory[drugCategory][durationIndex]) {
        throw new Error(`Ingen reduksjonsparametere funnet for varighet: ${durationOfUse}`);
    }

    return paramsByCategory[drugCategory][durationIndex];
}

export const calculateDurationInWeeks = (start, end) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const weeksDifference = endDate.diff(startDate, 'week', true);
    return weeksDifference.toFixed(1).replace('.', ',');
};

export function calculateReductionPlan(params) {

    const initialDrug = getDrugInfo(params.brandId);
    const genericName = initialDrug.genericName;

    // If we're asked to use the original drug, let's skip findBestReductionPlan
    // and directly generate a reduction plan with only that drug's packages.

    // if (params.useOriginalDrug) {

        const drug = getDrugInfo(params.brandId);
        const plan = generateReductionPlanForDrug(params, genericName, drug, true);
        return { ...plan, drug };

    // }

    // const { bestDrug, bestPlan } = findBestReductionPlan(params, genericName);

    // if (!bestDrug || !bestPlan) {
    //     throw new Error('Kan ikke finne et passende legemiddel som kan oppfylle doseringen med tilgjengelige styrker og delbarhet.');
    // }

    // return { ...bestPlan, drug: bestDrug };
}

// function findBestReductionPlan(params, genericName) {
//     const brandsOfGenericName = brands.filter(b => b.genericName === genericName);
//     let bestDrug = null;
//     let bestPlan = null;
//     let bestScore = Infinity;

//     for (let brand of brandsOfGenericName) {
//         try {
//             const drug = getDrugInfo(brand.brandId);
//             const plan = generateReductionPlanForDrug(params, genericName, drug, false);

//             const planScore = evaluateReductionPlan(plan);

//             if (planScore < bestScore) {
//                 bestScore = planScore;
//                 bestDrug = drug;
//                 bestPlan = plan;
//             }
//         } catch (error) {
//             continue;
//         }
//     }

//     return { bestDrug, bestPlan };
// }

function generateReductionPlanForDrug(params, genericName, drug, forceSingleBrand) {

    const {
        currentDosage,
        currentDuration,
        startDate,
        endDateManualOverride,
        endDate,
        numberOfDosesPerDay,
        dosePriority,
        // numberOfStepsManualOverride,
        // manualOverrideOption,
        selectedStrengths,
        dosageStrengthAutomaticOverride,
    } = params;

    const startingDose = parseFloat(currentDosage);
    const reductionParams = getReductionParameters(drug.drugType, currentDuration);

    let totalDurationDays, numberOfSteps, daysPerStep;

    if (endDateManualOverride && /*manualOverrideOption === 'endDate' && */endDate) {
        totalDurationDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
        daysPerStep = reductionParams.daysPerStep;
        numberOfSteps = Math.floor(totalDurationDays / daysPerStep);
    /*} else if (endDateManualOverride && manualOverrideOption === 'numberOfSteps' && numberOfStepsManualOverride) {
        numberOfSteps = parseInt(numberOfStepsManualOverride);
        daysPerStep = reductionParams.daysPerStep;
        totalDurationDays = numberOfSteps * daysPerStep; */
    } else {
        totalDurationDays = reductionParams.totalWeeks * 7;
        daysPerStep = reductionParams.daysPerStep;
        numberOfSteps = Math.floor(totalDurationDays / daysPerStep);
    }

    // let { strengths, packagesByStrength } = getStrengthsAndPackagesByGenericName(genericName);
    let { strengths, packagesByStrength } = getStrengthsAndPackagesByBrand(drug);

    // If we are forcing the use of a single brand (original drug), filter packages
    // to only include packages from the current drug's brand.
    if (forceSingleBrand) {
        for (let strength in packagesByStrength) {
            packagesByStrength[strength] = packagesByStrength[strength].filter(pkg => pkg.brandId === drug.brandId);
        }
    }

    let availableStrengths = selectedStrengths && selectedStrengths.length > 0 && !dosageStrengthAutomaticOverride
        ? selectedStrengths.slice()
        : strengths.slice();

    availableStrengths.sort((a, b) => b - a);

    // let divisible = 'yes';
    let divisible = drug.divisible; // Fixme: Kodeks fix

    const smallestPossibleDose = getSmallestPossibleDose({ strengths: availableStrengths, divisible: divisible });

    let calculatedNumberOfSteps = numberOfSteps;
    if (startingDose <= smallestPossibleDose) {
        calculatedNumberOfSteps = 1;
    }

    const percentageReductionPerStep = calculatePercentageReductionPerStep(startingDose, { strengths: availableStrengths, divisible: divisible }, calculatedNumberOfSteps);

    let doses = [];
    let currentDose = startingDose;
    let previousDoseDistribution = null;
    let totalTabletCounts = {};

    for (let i = 0; i < calculatedNumberOfSteps; i++) {
        const stepStartDate = dayjs(startDate).add(i * daysPerStep, 'day');
        const stepEndDate = stepStartDate.add(daysPerStep - 1, 'day');

        let dosesPerDay = parseInt(numberOfDosesPerDay) || drug.timesPerDay || 1;

        let doseDistribution = distributeDoseOverDay(
            currentDose,
            dosesPerDay,
            dosePriority,
            { strengths: availableStrengths, divisible: divisible, timesPerDay: drug.timesPerDay },
            previousDoseDistribution,
            availableStrengths
        );

        let tabletCombinationResult = calculateTabletCombinationForTimes(doseDistribution, { strengths: availableStrengths, divisible: divisible }, availableStrengths);

        if (tabletCombinationResult.warning) {
            throw new Error(tabletCombinationResult.warning);
        }

        const tabletCombination = tabletCombinationResult;
        const stepTotalTablets = {};

        for (let [strength, count] of Object.entries(tabletCombination.totalTabletCounts)) {
            const totalTabletsForStep = count * daysPerStep;
            stepTotalTablets[strength] = totalTabletsForStep;

            if (totalTabletCounts[strength]) {
                totalTabletCounts[strength] += totalTabletsForStep;
            } else {
                totalTabletCounts[strength] = totalTabletsForStep;
            }
        }

        const strengthsUsed = Object.keys(tabletCombination.totalTabletCounts);

        const dpd = doseDistribution.map(d => ({
            ...d,
            tablets: tabletCombination.perDoseTabletInfo.find(info => info.time === d.time)?.tablets || {}
        }));
        // const dpd = tabletCombination.perDoseTabletInfo;

        doses.push({
            stepNumber: i + 1,
            dose: currentDose,
            startDate: stepStartDate.format('YYYY-MM-DD'),
            endDate: stepEndDate.format('YYYY-MM-DD'),
            stepDurationDays: daysPerStep,
            dosesPerDay: dpd,
            tabletCombination,
            stepTotalTablets,
            strengthsUsed,
        });

        previousDoseDistribution = doseDistribution;

        if (currentDose > smallestPossibleDose) {
            let nextDose = calculateNextDoseExponential(currentDose, percentageReductionPerStep, { strengths: availableStrengths, divisible: divisible }, availableStrengths);
            if (nextDose >= currentDose) {
                nextDose = Math.max(smallestPossibleDose, currentDose - smallestPossibleDose);
                if (nextDose >= currentDose) {
                    break;
                }
            }
            currentDose = nextDose;
        } else if (currentDose === smallestPossibleDose) {
            break;
        }
    }

    if (currentDose > 0 && currentDose !== smallestPossibleDose) {
        currentDose = smallestPossibleDose;
    }

    if (currentDose === smallestPossibleDose && currentDose > 0) {
        const stepStartDate = dayjs(startDate).add(doses.length * daysPerStep, 'day');
        const stepEndDate = stepStartDate.add(daysPerStep - 1, 'day');

        let dosesPerDay = parseInt(numberOfDosesPerDay) || drug.timesPerDay || 1;

        let doseDistribution = distributeDoseOverDaySingleDose(
            currentDose,
            dosesPerDay,
            dosePriority,
            { strengths: availableStrengths, divisible: divisible },
            availableStrengths
        );

        let tabletCombinationResult = calculateTabletCombinationForTimes(doseDistribution, { strengths: availableStrengths, divisible: divisible }, availableStrengths);
        if (tabletCombinationResult.warning) {
            throw new Error(tabletCombinationResult.warning);
        }

        const tabletCombination = tabletCombinationResult;
        const stepTotalTablets = {};
        for (let [strength, count] of Object.entries(tabletCombination.totalTabletCounts)) {
            const totalTabletsForStep = count * daysPerStep;
            stepTotalTablets[strength] = totalTabletsForStep;
        }

        const dpd = doseDistribution.map(d => ({
            ...d,
            tablets: tabletCombination.perDoseTabletInfo.find(info => info.time === d.time)?.tablets || {}
        }));
        // const dpd = tabletCombination.perDoseTabletInfo;

        doses.push({
            stepNumber: doses.length + 1,
            dose: currentDose,
            startDate: stepStartDate.format('YYYY-MM-DD'),
            endDate: stepEndDate.format('YYYY-MM-DD'),
            stepDurationDays: daysPerStep,
            dosesPerDay: dpd,
            tabletCombination,
            stepTotalTablets,
            strengthsUsed: Object.keys(tabletCombination.totalTabletCounts),
        });

        currentDose = 0;
        const zeroDoseStartDate = stepEndDate.add(1, 'day');
        const zeroDoseEndDate = zeroDoseStartDate.add(daysPerStep - 1, 'day');
        let zeroDoseDistribution = doseTimesZeroDose(dosesPerDay);

        doses.push({
            stepNumber: doses.length + 1,
            dose: currentDose,
            startDate: zeroDoseStartDate.format('YYYY-MM-DD'),
            endDate: zeroDoseEndDate.format('YYYY-MM-DD'),
            stepDurationDays: daysPerStep,
            dosesPerDay: zeroDoseDistribution,
            tabletCombination: { totalTabletCounts: {}, perDoseTabletInfo: [] },
            stepTotalTablets: {},
            strengthsUsed: [],
        });
    }

    let tabletsNeededPerStep = calculateTabletsAndPackagesPerStep(doses, packagesByStrength);

    const finalTotalTabletCounts = doses.reduce((acc, stp) => {
        if (!stp.rusfri) {
            for (let [strength, count] of Object.entries(stp.stepTotalTablets)) {
                acc[strength] = (acc[strength] || 0) + count;
            }
        }
        return acc;
    }, {});
    const tabletsNeeded = optimizeTotalTabletsAndPackages(finalTotalTabletCounts, packagesByStrength);

    doses = doses.map((step) => {
        if (!step.rusfri) {
            return { ...step, packagesNeeded: tabletsNeededPerStep[step.stepNumber] };
        } else {
            return { ...step };
        }
    });

    let actualEndDate = doses.length > 0 ? doses[doses.length - 1].endDate : endDate;

    // Reintroduce code to hide the last zero-dose step and add rusfri step
    if (doses.length > 0) {
        const lastStep = doses[doses.length - 1];
        if (!lastStep.rusfri && lastStep.dose === 0) {
            const rusfriDate = lastStep.startDate;
            lastStep.hiddenFromTable = true; // Hide from table but used for chart data
            doses.push({ rusfri: { date: rusfriDate } });
            const rusfriDay = dayjs(rusfriDate, 'YYYY-MM-DD', true);
            actualEndDate = rusfriDay.subtract(1, 'day').format('YYYY-MM-DD');
        }
    }

    return { doses, tabletsNeeded, actualEndDate };
}

// function evaluateReductionPlan(plan) {
//     let score = 0;

//     const totalTabletsPrescribed = plan.tabletsNeeded.reduce((acc, item) => {
//         return acc + (item.numPackages * item.packageSize);
//     }, 0);

//     const totalTabletsConsumed = Object.values(plan.doses.reduce((acc, step) => {
//         if (!step.rusfri) {
//             for (let [strength, count] of Object.entries(step.stepTotalTablets)) {
//                 acc[strength] = (acc[strength] || 0) + count;
//             }
//         }
//         return acc;
//     }, {})).reduce((sum, val) => sum + val, 0);

//     let totalStrengthsUsed = 0;
//     let totalDoses = 0;
//     const doseSteps = plan.doses.filter(d => !d.rusfri);
//     for (let dose of doseSteps) {
//         for (let doseInfo of dose.tabletCombination.perDoseTabletInfo) {
//             totalStrengthsUsed += Object.keys(doseInfo.tablets).length;
//             totalDoses += 1;
//         }
//     }
//     const averageStrengthsPerDose = totalDoses > 0 ? totalStrengthsUsed / totalDoses : 0;

//     let percentageReductions = [];
//     for (let i = 1; i < doseSteps.length; i++) {
//         const prevDose = doseSteps[i - 1].dose;
//         const currDose = doseSteps[i].dose;
//         if (prevDose > 0) {
//             const reduction = ((prevDose - currDose) / prevDose) * 100;
//             percentageReductions.push(reduction);
//         }
//     }
//     const meanReduction = percentageReductions.reduce((a, b) => a + b, 0) / (percentageReductions.length || 1);
//     const variance = percentageReductions.reduce((sum, val) => sum + Math.pow(val - meanReduction, 2), 0) / (percentageReductions.length || 1);
//     const stdDevReduction = Math.sqrt(variance);

//     const overPrescribedTablets = totalTabletsPrescribed - totalTabletsConsumed;

//     score = totalTabletsConsumed + averageStrengthsPerDose * 10 + stdDevReduction * 5 + overPrescribedTablets * 2;

//     return score;
// }

export function calculatePercentageReductionPerStep(startingDose, drug, numberOfSteps) {

    const smallestPossibleDose = getSmallestPossibleDose(drug);

    if (startingDose <= smallestPossibleDose) {
        return 100;
    }

    const adjustedNumberOfSteps = numberOfSteps - 1;

    const percentageReductionPerStep = 100 * (1 - Math.pow(smallestPossibleDose / startingDose, 1 / adjustedNumberOfSteps));

    return percentageReductionPerStep;
}

export function calculateNextDoseExponential(currentDose, percentageReductionPerStep, drug) {

    let smallestPossibleDose = getSmallestPossibleDose(drug);
    let nextDose = currentDose * (1 - percentageReductionPerStep / 100);
    nextDose = Math.max(nextDose, smallestPossibleDose);
    nextDose = Math.round(nextDose / smallestPossibleDose) * smallestPossibleDose;
    nextDose = parseFloat(nextDose.toFixed(2));

    return nextDose;
}

export function getSmallestPossibleDose(drug) {

    let strengths = drug.strengths.slice();

    if ((drug.divisible === 'yes')) {
        strengths = strengths.concat(strengths.map(s => s / 2));
    }

    strengths = strengths.filter((v, i, a) => a.indexOf(v) === i);
    return Math.min(...strengths);
}

export function distributeDoseOverDaySingleDose(totalDose, dosesPerDay, dosePriority, drug) {
    let doseTimes = getDoseTimes(dosesPerDay);
    let priorityIndex = doseTimes.findIndex(time => time === dosePriority);
    if (priorityIndex === -1) priorityIndex = 0;

    let doseDistribution = doseTimes.map((time, index) => ({
        time,
        dose: index === priorityIndex ? totalDose : 0,
    }));

    return doseDistribution;

}

function doseTimesZeroDose(dosesPerDay) {
    let doseTimes = getDoseTimes(dosesPerDay);
    return doseTimes.map(time => ({
        time,
        dose: 0,
    }));
}

export function distributeDoseOverDay(totalDose, dosesPerDay, dosePriority, drug, previousDoseDistribution, selectedStrengths) {
    dosesPerDay = parseInt(dosesPerDay) || drug.timesPerDay || 1;
    let doseTimes = getDoseTimes(dosesPerDay);
    let doseDistribution = [];
    let remainingDose = totalDose;
    let doses = doseTimes.length;
    let smallestPossibleDose = getSmallestPossibleDose(drug);

    if (remainingDose === 0) {
        doseDistribution = doseTimes.map(time => ({
            time,
            dose: 0,
        }));
        return doseDistribution;
    }

    doseDistribution = doseTimes.map(time => ({
        time,
        dose: 0,
    }));

    const baseDose = Math.floor((remainingDose / doses) / smallestPossibleDose) * smallestPossibleDose;
    let assignedDose = baseDose * doses;
    remainingDose -= assignedDose;

    doseDistribution = doseDistribution.map(d => ({
        ...d,
        dose: baseDose,
    }));

    if (remainingDose > 0) {
        let priorityIndex = doseTimes.findIndex(time => time === dosePriority);
        if (priorityIndex === -1) priorityIndex = 0;

        let index = priorityIndex;
        while (remainingDose >= smallestPossibleDose) {
            doseDistribution[index].dose += smallestPossibleDose;
            remainingDose -= smallestPossibleDose;
            index = (index + 1) % doses;
        }
    }

    if (remainingDose > 0) {
        doseDistribution[doses - 1].dose += remainingDose;
        remainingDose = 0;
    }

    if (previousDoseDistribution) {
        for (let i = 0; i < doseDistribution.length; i++) {
            if (doseDistribution[i].dose > previousDoseDistribution[i].dose) {
                let excessDose = doseDistribution[i].dose - previousDoseDistribution[i].dose;
                doseDistribution[i].dose = previousDoseDistribution[i].dose;
                remainingDose += excessDose;

                let redistributeIndex = (i + 1) % doseDistribution.length;
                while (remainingDose >= smallestPossibleDose) {
                    doseDistribution[redistributeIndex].dose += smallestPossibleDose;
                    remainingDose -= smallestPossibleDose;
                    redistributeIndex = (redistributeIndex + 1) % doseDistribution.length;
                }
            }
        }
    }

    doseDistribution = doseDistribution.map(d => ({ ...d, dose: parseFloat(d.dose.toFixed(2)) }));

    return doseDistribution;
}

export function getDoseTimes(dosesPerDay) {
    const timesByDoseCount = {
        1: ['Morgen'],
        2: ['Morgen', 'Kveld'],
        3: ['Morgen', 'Middag', 'Kveld'],
        4: ['Morgen', 'Formiddag', 'Ettermiddag', 'Kveld']
    };
    return timesByDoseCount[dosesPerDay] || ['Morgen'];
}

export function calculateTabletCombinationForTimes(doseDistribution, drug, selectedStrengths) {

    const totalTabletCounts = {};
    const perDoseTabletInfo = [];

    for (let doseInfo of doseDistribution) {

        if (doseInfo.dose > 0) {
            const tabletCombinationResult = calculateTabletCombination(doseInfo.dose, drug, selectedStrengths);

            if (tabletCombinationResult.warning) {
                return { warning: tabletCombinationResult.warning };
            }

            perDoseTabletInfo.push({
                time: doseInfo.time,
                dose: doseInfo.dose,
                tablets: tabletCombinationResult.tabletCounts,
            });

            for (let [strength, count] of Object.entries(tabletCombinationResult.tabletCounts)) {
                if (totalTabletCounts[strength]) {
                    totalTabletCounts[strength] += count;
                } else {
                    totalTabletCounts[strength] = count;
                }
            }
        } else {
            perDoseTabletInfo.push({
                time: doseInfo.time,
                dose: 0,
                tablets: {},
            });
        }
    }

    return { totalTabletCounts, perDoseTabletInfo };
}

export function calculateTabletCombination(totalDose, drug, selectedStrengths) {

    let strengths = selectedStrengths && selectedStrengths.length > 0 ? selectedStrengths.slice() : drug.strengths.slice();

    strengths = strengths.sort((a, b) => {
        const aDivisible = drug.divisible === 'yes' && Number.isInteger(a / 2);
        const bDivisible = drug.divisible === 'yes' && Number.isInteger(b / 2);
        if (aDivisible !== bDivisible) {
            return bDivisible - aDivisible;
        }
        return b - a;
    });

    let remainingDose = totalDose;
    const tabletCounts = {};

    for (let strength of strengths) {

        if (drug.divisible === 'yes') {
            while (remainingDose >= strength) {
                tabletCounts[strength] = (tabletCounts[strength] || 0) + 1;
                remainingDose -= strength;
                remainingDose = parseFloat(remainingDose.toFixed(4));
            }
            while (remainingDose >= strength / 2) {
                tabletCounts[strength] = (tabletCounts[strength] || 0) + 0.5;
                remainingDose -= strength / 2;
                remainingDose = parseFloat(remainingDose.toFixed(4));
            }
        } else {
            while (remainingDose >= strength) {
                tabletCounts[strength] = (tabletCounts[strength] || 0) + 1;
                remainingDose -= strength;
                remainingDose = parseFloat(remainingDose.toFixed(4));
            }
        }

        if (remainingDose <= 0) break;
    }

    remainingDose = parseFloat(remainingDose.toFixed(2));

    if (remainingDose > 0) {
        return { warning: 'Kan ikke finne en nøyaktig dosering med tilgjengelige tabletter.' };
    }

    return { tabletCounts };
}

export function calculateTabletsAndPackages(totalTabletCounts, packagesByStrength) {

    const tabletsNeeded = [];

    for (let [strength, totalTablets] of Object.entries(totalTabletCounts)) {
        const originalStrength = parseFloat(strength);

        const totalTabletsNeeded = Math.ceil(totalTablets);

        const packages = packagesByStrength[originalStrength];

        if (!packages || packages.length === 0) {
            throw new Error(`Ingen tilgjengelige pakninger funnet for styrke ${originalStrength} mg.`);
        }

        packages.sort((a, b) => {
            const aOver = a.packageSize - totalTabletsNeeded % a.packageSize;
            const bOver = b.packageSize - totalTabletsNeeded % b.packageSize;
            return aOver - bOver || a.packageSize - b.packageSize;
        });

        let remainingTablets = totalTabletsNeeded;
        const packagesNeeded = [];

        for (let pkg of packages) {
            if (remainingTablets <= 0) break;
            let numPackages = Math.ceil(remainingTablets / pkg.packageSize);
            if (numPackages > 0) {
                packagesNeeded.push({
                    packageSize: pkg.packageSize,
                    strength: originalStrength,
                    numPackages: numPackages,
                    brandName: pkg.brandName,
                    brandId: pkg.brandId,
                });
                remainingTablets -= numPackages * pkg.packageSize;
            }
        }

        const combinedPackages = {};
        for (let pkg of packagesNeeded) {
            const key = `${pkg.brandName}-${pkg.strength}-${pkg.packageSize}`;
            if (combinedPackages[key]) {
                combinedPackages[key].numPackages += pkg.numPackages;
            } else {
                combinedPackages[key] = { ...pkg };
            }
        }

        tabletsNeeded.push(...Object.values(combinedPackages));
    }

    return tabletsNeeded;
}

export function calculateTabletsAndPackagesPerStep(doses, packagesByStrength) {
    let leftoverTablets = {};
    let tabletsNeededPerStep = {};

    for (let step of doses) {
        if (step.rusfri) {
            tabletsNeededPerStep[step.stepNumber || 'rusfri'] = [];
            continue;
        }

        let tabletsNeededThisStep = [];
        let stepTotalTablets = { ...step.stepTotalTablets };

        for (let strength in leftoverTablets) {
            if (stepTotalTablets[strength]) {
                const usedTablets = Math.min(stepTotalTablets[strength], leftoverTablets[strength]);
                stepTotalTablets[strength] -= usedTablets;
                leftoverTablets[strength] -= usedTablets;
            }
        }

        for (let strength in stepTotalTablets) {
            if (stepTotalTablets[strength] <= 0) {
                delete stepTotalTablets[strength];
            }
        }

        tabletsNeededThisStep = calculateTabletsAndPackages(stepTotalTablets, packagesByStrength);

        for (let pkg of tabletsNeededThisStep) {
            const totalTablets = pkg.packageSize * pkg.numPackages;
            const unusedTablets = totalTablets - (step.stepTotalTablets[pkg.strength] || 0);
            if (leftoverTablets[pkg.strength]) {
                leftoverTablets[pkg.strength] += unusedTablets;
            } else {
                leftoverTablets[pkg.strength] = unusedTablets;
            }
        }

        tabletsNeededPerStep[step.stepNumber] = tabletsNeededThisStep;
    }

    return tabletsNeededPerStep;
}

export function optimizeTotalTabletsAndPackages(totalTabletCounts, packagesByStrength) {
    const tabletsNeeded = calculateTabletsAndPackages(totalTabletCounts, packagesByStrength);

    const combinedPackages = {};

    for (let pkg of tabletsNeeded) {
        const key = `${pkg.brandName}-${pkg.strength}-${pkg.packageSize}`;
        if (combinedPackages[key]) {
            combinedPackages[key].numPackages += pkg.numPackages;
        } else {
            combinedPackages[key] = { ...pkg };
        }
    }

    const finalTabletsNeeded = Object.values(combinedPackages);

    return finalTabletsNeeded;
}
