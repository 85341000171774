export const tabletPackages = [

    //strength: strength/dosage pr tablet, usually milligrams
    //halvingLine: the tablet provides an indented line which helps split the tablet in half
    //divisible: the tablet can be divided into smaller pieces without changing the absorption of the active drug. (with or without halving line) Some tablets are in capsules that cannot be cut into smaller doses.
    //packageSize: amount of tablets per box/package


    {
        "tabletId":1,
        "brandId":1,
        "strength":5,
        "packageSize":500,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":2,
        "brandId":1,
        "strength":10,
        "packageSize":25,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":3,
        "brandId":1,
        "strength":10,
        "packageSize":100,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":4,
        "brandId":1,
        "strength":10,
        "packageSize":500,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":5,
        "brandId":1,
        "strength":15,
        "packageSize":25,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":6,
        "brandId":1,
        "strength":15,
        "packageSize":100,
        "halvingLine":"unknown",
        "divisible":"yes"
    },
    {
        "tabletId":7,
        "brandId":2,
        "strength":10,
        "packageSize":25,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":8,
        "brandId":2,
        "strength":10,
        "packageSize":49,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":9,
        "brandId":2,
        "strength":10,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":10,
        "brandId":2,
        "strength":15,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":11,
        "brandId":2,
        "strength":15,
        "packageSize":49,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":12,
        "brandId":2,
        "strength":15,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":13,
        "brandId":2,
        "strength":25,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":14,
        "brandId":2,
        "strength":25,
        "packageSize":49,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":15,
        "brandId":2,
        "strength":25,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"yes"
    },
    {
        "tabletId":16,
        "brandId":4,
        "strength":5,
        "packageSize":25,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":17,
        "brandId":4,
        "strength":5,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":18,
        "brandId":5,
        "strength":2,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":19,
        "brandId":5,
        "strength":2,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":20,
        "brandId":5,
        "strength":2,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":21,
        "brandId":5,
        "strength":5,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":22,
        "brandId":5,
        "strength":5,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":23,
        "brandId":5,
        "strength":5,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":24,
        "brandId":6,
        "strength":5,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":25,
        "brandId":6,
        "strength":10,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":26,
        "brandId":6,
        "strength":10,
        "packageSize":50,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":27,
        "brandId":6,
        "strength":10,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":28,
        "brandId":6,
        "strength":15,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":29,
        "brandId":6,
        "strength":15,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":30,
        "brandId":6,
        "strength":20,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":31,
        "brandId":6,
        "strength":20,
        "packageSize":50,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":32,
        "brandId":6,
        "strength":20,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":33,
        "brandId":6,
        "strength":30,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":34,
        "brandId":6,
        "strength":30,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":35,
        "brandId":6,
        "strength":40,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":36,
        "brandId":6,
        "strength":40,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":37,
        "brandId":6,
        "strength":60,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":38,
        "brandId":6,
        "strength":80,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":39,
        "brandId":6,
        "strength":80,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":40,
        "brandId":6,
        "strength":120,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":41,
        "brandId":7,
        "strength":5,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":42,
        "brandId":7,
        "strength":5,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":43,
        "brandId":7,
        "strength":10,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":44,
        "brandId":7,
        "strength":10,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":45,
        "brandId":7,
        "strength":20,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":46,
        "brandId":7,
        "strength":20,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":47,
        "brandId":8,
        "strength":5,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":48,
        "brandId":8,
        "strength":10,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":49,
        "brandId":8,
        "strength":10,
        "packageSize":50,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":50,
        "brandId":8,
        "strength":10,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":51,
        "brandId":8,
        "strength":15,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":52,
        "brandId":8,
        "strength":15,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":53,
        "brandId":8,
        "strength":20,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":54,
        "brandId":8,
        "strength":20,
        "packageSize":50,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":55,
        "brandId":8,
        "strength":20,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":56,
        "brandId":8,
        "strength":30,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":57,
        "brandId":8,
        "strength":30,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":58,
        "brandId":8,
        "strength":40,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":59,
        "brandId":8,
        "strength":40,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":60,
        "brandId":8,
        "strength":60,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":61,
        "brandId":8,
        "strength":80,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":62,
        "brandId":8,
        "strength":80,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":63,
        "brandId":9,
        "strength":5,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":64,
        "brandId":9,
        "strength":5,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":65,
        "brandId":9,
        "strength":5,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":66,
        "brandId":9,
        "strength":10,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":67,
        "brandId":9,
        "strength":10,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":68,
        "brandId":9,
        "strength":10,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":69,
        "brandId":9,
        "strength":15,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":70,
        "brandId":9,
        "strength":15,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":71,
        "brandId":9,
        "strength":20,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":72,
        "brandId":9,
        "strength":20,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":73,
        "brandId":9,
        "strength":20,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":74,
        "brandId":9,
        "strength":30,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":75,
        "brandId":9,
        "strength":30,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":76,
        "brandId":9,
        "strength":40,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":77,
        "brandId":9,
        "strength":40,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":78,
        "brandId":9,
        "strength":40,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":79,
        "brandId":10,
        "strength":5,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":80,
        "brandId":10,
        "strength":5,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":81,
        "brandId":10,
        "strength":10,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":82,
        "brandId":10,
        "strength":10,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":83,
        "brandId":10,
        "strength":20,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":84,
        "brandId":10,
        "strength":20,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":85,
        "brandId":10,
        "strength":30,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":86,
        "brandId":10,
        "strength":30,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":87,
        "brandId":10,
        "strength":40,
        "packageSize":28,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":88,
        "brandId":10,
        "strength":40,
        "packageSize":98,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":89,
        "brandId":11,
        "strength":5,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":90,
        "brandId":11,
        "strength":10,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":91,
        "brandId":11,
        "strength":10,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":92,
        "brandId":11,
        "strength":30,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":93,
        "brandId":11,
        "strength":30,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":94,
        "brandId":11,
        "strength":60,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":95,
        "brandId":11,
        "strength":100,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":96,
        "brandId":11,
        "strength":100,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":97,
        "brandId":11,
        "strength":200,
        "packageSize":90,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":98,
        "brandId":12,
        "strength":10,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":99,
        "brandId":12,
        "strength":10,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":100,
        "brandId":12,
        "strength":30,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":101,
        "brandId":12,
        "strength":30,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":102,
        "brandId":12,
        "strength":60,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":103,
        "brandId":12,
        "strength":60,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":104,
        "brandId":12,
        "strength":100,
        "packageSize":25,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":105,
        "brandId":12,
        "strength":100,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":106,
        "brandId":13,
        "strength":10,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":107,
        "brandId":13,
        "strength":30,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":108,
        "brandId":3,
        "strength":2,
        "packageSize":25,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":109,
        "brandId":3,
        "strength":2,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":110,
        "brandId":3,
        "strength":2,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":111,
        "brandId":3,
        "strength":5,
        "packageSize":25,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":112,
        "brandId":3,
        "strength":5,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":113,
        "brandId":3,
        "strength":5,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":114,
        "brandId":14,
        "strength":30,
        "packageSize":10,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":115,
        "brandId":14,
        "strength":30,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":116,
        "brandId":14,
        "strength":30,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":117,
        "brandId":14,
        "strength":30,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":118,
        "brandId":15,
        "strength":30,
        "packageSize":10,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":119,
        "brandId":15,
        "strength":30,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":120,
        "brandId":15,
        "strength":30,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":121,
        "brandId":15,
        "strength":30,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":122,
        "brandId":16,
        "strength":30,
        "packageSize":10,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":123,
        "brandId":16,
        "strength":30,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":124,
        "brandId":16,
        "strength":30,
        "packageSize":50,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":125,
        "brandId":16,
        "strength":30,
        "packageSize":100,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":126,
        "brandId":17,
        "strength":25,
        "packageSize":20,
        "halvingLine":"yes",
        "divisible":"yes"
    },
    {
        "tabletId":127,
        "brandId":18,
        "strength":50,
        "packageSize":20,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":128,
        "brandId":18,
        "strength":50,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":129,
        "brandId":19,
        "strength":100,
        "packageSize":20,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":130,
        "brandId":19,
        "strength":100,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":131,
        "brandId":19,
        "strength":150,
        "packageSize":20,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":132,
        "brandId":19,
        "strength":150,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":133,
        "brandId":19,
        "strength":200,
        "packageSize":20,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":134,
        "brandId":19,
        "strength":200,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":135,
        "brandId":20,
        "strength":50,
        "packageSize":20,
        "halvingLine":"no",
        "divisible":"no"
    },
    {
        "tabletId":136,
        "brandId":20,
        "strength":50,
        "packageSize":100,
        "halvingLine":"no",
        "divisible":"no"
    }
];

export const brands = [
    {
        "brandId":1,
        "brandName":"Delipam",
        "atcCode":"N05B A04",
        "drugType":"Benzodiazepiner",
        "genericName":"Oksazepam",
        "timesPerDay":4
    },
    {
        "brandId":2,
        "brandName":"Sobril",
        "atcCode":"N05B A04",
        "drugType":"Benzodiazepiner",
        "genericName":"Oksazepam",
        "timesPerDay":4
    },
    {
        "brandId":3,
        "brandName":"Stesolid",
        "atcCode":"N05B A01",
        "drugType":"Benzodiazepiner",
        "genericName":"Diazepam",
        "timesPerDay":2
    },
    {
        "brandId":4,
        "brandName":"Valium",
        "atcCode":"N05B A01",
        "drugType":"Benzodiazepiner",
        "genericName":"Diazepam",
        "timesPerDay":2
    },
    {
        "brandId":5,
        "brandName":"Vival",
        "atcCode":"N05B A01",
        "drugType":"Benzodiazepiner",
        "genericName":"Diazepam",
        "timesPerDay":2
    },
    {
        "brandId":6,
        "brandName":"OxyContin",
        "atcCode":"N02A A05",
        "drugType":"Opioder",
        "genericName":"Oksykodon depot",
        "timesPerDay":2
    },
    {
        "brandId":7,
        "brandName":"OxyNorm",
        "atcCode":"N02A A05",
        "drugType":"Opioder",
        "genericName":"Oksykodon",
        "timesPerDay":4
    },
    {
        "brandId":8,
        "brandName":"Reltebon Depot",
        "atcCode":"N02A A05",
        "drugType":"Opioder",
        "genericName":"Oksykodon depot",
        "timesPerDay":2
    },
    {
        "brandId":9,
        "brandName":"Targiniq",
        "atcCode":"N02A A55",
        "drugType":"Opioder",
        "genericName":"Oksykodon depot + nalokson",
        "timesPerDay":2
    },
    {
        "brandId":10,
        "brandName":"Tanonalla",
        "atcCode":"N02A A55",
        "drugType":"Opioder",
        "genericName":"Oksykodon depot + nalokson",
        "timesPerDay":2
    },
    {
        "brandId":11,
        "brandName":"Dolcontin",
        "atcCode":"N02A A01",
        "drugType":"Opioder",
        "genericName":"Morfin depot",
        "timesPerDay":2
    },
    {
        "brandId":12,
        "brandName":"Malfin",
        "atcCode":"N02A A01",
        "drugType":"Opioder",
        "genericName":"Morfin depot",
        "timesPerDay":2
    },
    {
        "brandId":13,
        "brandName":"Morfin",
        "atcCode":"N02A A01",
        "drugType":"Opioder",
        "genericName":"Morfin",
        "timesPerDay":4
    },
    {
        "brandId":14,
        "brandName":"Paralgin forte",
        "atcCode":"N02A J06",
        "drugType":"Svake opioider",
        "genericName":"Kodein + paracetamol",
        "timesPerDay":4
    },
    {
        "brandId":15,
        "brandName":"Altermol",
        "atcCode":"N02A J06",
        "drugType":"Svake opioider",
        "genericName":"Kodein + paracetamol",
        "timesPerDay":4
    },
    {
        "brandId":16,
        "brandName":"Pinex forte",
        "atcCode":"N02A J06",
        "drugType":"Svake opioider",
        "genericName":"Kodein + paracetamol",
        "timesPerDay":4
    },
    {
        "brandId":17,
        "brandName":"Kodein",
        "atcCode":"R05D A04",
        "drugType":"Svake opioider",
        "genericName":"Kodein",
        "timesPerDay":4
    },
    {
        "brandId":18,
        "brandName":"Nobligan",
        "atcCode":"N02A X02",
        "drugType":"Svake opioider",
        "genericName":"Tramadol",
        "timesPerDay":3
    },
    {
        "brandId":19,
        "brandName":"Nobligan Retard",
        "atcCode":"N02A X02",
        "drugType":"Svake opioider",
        "genericName":"Tramadol depot",
        "timesPerDay":2
    },
    {
        "brandId":20,
        "brandName":"Tramadol",
        "atcCode":"N02A X02",
        "drugType":"Svake opioider",
        "genericName":"Tramadol",
        "timesPerDay":3
    }
];
