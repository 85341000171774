import React, { useState } from 'react'
import { brands, tabletPackages } from '../Database/Drugs'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg"
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, StepIcon, FormGroup} from "@mui/material"

function Step1({
  drug,
  setDrug,
  currentDosage,
  setCurrentDosage,
  currentDuration,
  setCurrentDuration,
  smallScreen,
  drugType
}) {

  const [placeholder, setPlaceholder] = useState('Eksempel: 100 mg')

  const handleDrugSelect = (event, value) => {

    if (value) {

      const brandTablets = tabletPackages.filter((tp) => tp.brandId === value.brandId)
      const strengths = [...new Set(brandTablets.map((tp) => tp.strength))]
      const divisible = brandTablets.some((tp) => tp.divisible === 'yes') ? 'yes' : 'no'

      const enrichedDrug = {
          ...value,
          strengths: strengths,
          divisible: divisible,
          drugType: value.drugType,
          timesPerDay: value.timesPerDay,
          name: value.brandName
      }

      setDrug(enrichedDrug)
      
      const needsCustomPlaceholder = ['Paralgin forte', 'Pinex forte', 'Altermol']
      
      let txt = 'Eksempel: 100 mg'
      if (needsCustomPlaceholder.includes(value.brandName)) txt = 'Skriv inn kodeindose i mg'

      setPlaceholder(txt)

    } else {

      setDrug(null)

    }

  }

  return (
      
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12}>
          <div className="TrappNed-step-icon-title">
            <StepIcon icon="1" />
            <div className="TrappNed-step-header">Preparat og dosering</div>
          </div>
        </Grid>
        <Grid item xs={smallScreen ? 12 : 6}>
          <FormGroup sx={{textAlign:'start'}}>
            <label className="TrappNed-small-title">Søk etter preparat</label>
            <Autocomplete
              id="autocomplete"
              disablePortal
              options={[...brands].sort((a, b) => a.brandName.localeCompare(b.brandName))} // Sorting alphabetically
              getOptionLabel={(option) => option.brandName}
              onChange={handleDrugSelect}
              value={drug}
              filterOptions={(options, {inputValue}) =>
                options.filter((option) =>
                  option.brandName.toLowerCase().includes(inputValue.toLowerCase()) ||
                  option.genericName.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              renderOption={(props, option) => (
                <li {...props}>
                  {option.brandName}<span>({option.genericName})</span>
                </li>
              )}
              renderInput={(params) => <TextField
                {...params}
                placeholder={drugType && drugType === 'opioder' ? 'Eksempel: Tramadol' : 'Eksempel: Zopiclon'}
                sx={{
                  '& .MuiInputBase-root': {
                    input: {
                      backgroundColor: '#fff',
                      border: 'none',
                    }
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <SearchIcon className="searchIcon" />
                  ),
                }}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={smallScreen ? 12 : 6}>
          <FormGroup sx={{textAlign:'start'}}>
            <label className="TrappNed-small-title">Skriv inn pasientens nåværende døgndose i mg</label>
            <TextField
              type="text"
              fullWidth={true}
              value={currentDosage}
              onChange={(e) => setCurrentDosage(e.target.value)}
              variant="outlined"
              placeholder={placeholder}
              sx={{
                '& .MuiInputBase-root': {
                  input: {
                    backgroundColor: '#fff',
                  }
                },
              }}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'start', mt: smallScreen ? 1 : 4}}>
          <FormControl component="fieldset">
            <label className="TrappNed-small-title">Hvor lenge har pasienten brukt legemiddelet?</label>
            <RadioGroup
              row
              aria-label="currentDuration"
              name="currentDuration"
              value={currentDuration}
              onChange={(event) => setCurrentDuration(event.target.value)}
            >
              <FormControlLabel value="1-3 måneder" control={<Radio/>} label="1&#8211;3 måneder"/>
              <FormControlLabel value="3-12 måneder" control={<Radio/>} label="3&#8211;12 måneder"/>
              <FormControlLabel value="Mer enn 1 år" control={<Radio/>} label="Mer enn 1 år"/>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Box>

  )

}

export default Step1
