import * as React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import { styled } from '@mui/material/styles'

const steps = [
  'Preparat og dosering',
  'Varighet av nedtrappingsplan',
  'Forslag til nedtrappingsplan'
];

const CustomStepConnector = styled(StepConnector)(({ theme, smallscreen }) => ({
  [`& .MuiStepConnector-line`]: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '3rem',
      height: smallscreen ? '0px' : '3px',
      backgroundColor: theme.palette.background.paper,
      left: '50%',
      transform: 'translate(-50%, -50%)',
      top: '0',
      zIndex: 1
    }
  }
}))

export default function HorizontalStepper({ activeStep, smallscreen, handleStepChange }) {

  return (

    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<CustomStepConnector smallscreen={smallscreen}/>}
      >
        {steps.map((label, step) => (
          <Step key={label} onClick={() => step < activeStep ? handleStepChange(step) : false}>
            <StepLabel
              sx={{
                '.MuiStepIcon-root': {
                  'circle': {
                    fill: 'white',
                    stroke: '#23262A',
                    strokeWidth: 1,
                    r: 11
                  },
                  'text': {
                    fill: '#23262A'
                  }
                },
                '.Mui-completed': {
                  fill: '#004D5D',
                  transform: 'scale(0.9575)',
                  transformOrigin: 'center'
                },
                '.Mui-active .MuiStepIcon-root circle': {
                  fill: '#000000'
                },
                '.Mui-completed .MuiStepIcon-root text': {
                  fill: '#fff'
                },
                '.Mui-active .MuiStepIcon-root text': {
                  fill: '#fff'
                },
                '.MuiStepLabel-labelContainer': {
                  '.MuiStepLabel-label': {
                      fontFamily: 'neue-haas-unica',
                      fontWeight: '400'
                  },
                  '.Mui-completed': {
                      textDecoration: 'underline'
                  },
                  '.Mui-active': {
                      fontWeight: '500'
                  }
                }
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>

  )

}
