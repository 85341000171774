import React, { useState, useRef, useEffect } from 'react'
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse, Box, Button } from '@mui/material'
import { StepIcon } from "@mui/material"
import { ReactComponent as AngleIcon } from "../assets/icons/angle.svg"
import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg"
import { ReactComponent as CaretIcon } from "../assets/icons/caret.svg"
import { ReactComponent as ChartIcon } from "../assets/icons/chart.svg"
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg"
import { ReactComponent as EyeIcon } from "../assets/icons/eye.svg"
import { ReactComponent as PDFIcon } from "../assets/icons/pdf.svg"
import { ReactComponent as StopIcon } from "../assets/icons/stop.svg"
import dayjs from 'dayjs'
import { calculateDurationInWeeks } from "../Components/utils"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Line } from 'react-chartjs-2'
import 'chart.js/auto'
import html2pdf from 'html2pdf.js'

function formatTabletCount(count) {

  if (count === 0.5) return '½'
  else if (Number.isInteger(count) || count % 1 === 0) return `${count}`
  else if (count % 1 === 0.5) return `${Math.floor(count)}½`
  else return count.toFixed(2)

}

function formatDose(dose) {

  let doseStr = dose.toFixed(1).replace('.', ',') + ' mg'
  return doseStr

}

// -------------------------------------------------------------
// AppointmentRow Component
// -------------------------------------------------------------
function AppointmentRow({ appointment, forceOpen = false, index, onVisibilityClick, rowsHidden, isLastStep = false }) {
    const [open, setOpen] = useState(false);
    const isOpen = forceOpen || open;

    const formattedDate = dayjs(appointment.date).format('DD.MM.YYYY');

    return (
        <>
            <TableRow sx={{ backgroundColor: '#E4DAD7', cursor: 'pointer' }}>
                <TableCell className="compact-xs">
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex'
                        }}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {isOpen && !isLastStep ? <AngleIcon className="up" fill="#004d5d" /> : !isLastStep ? <AngleIcon className="down" fill="#004d5d" /> : ''}
                        </IconButton>
                        <IconButton
                            aria-label="toggle visibility"
                            size="small"
                            onClick={() => onVisibilityClick(index)}
                        >
                            {rowsHidden && isLastStep ? <span title={'Vis resten av nedtrappingsplanen'}><EyeIcon/></span> : !rowsHidden ? <span
                                title={'Stopp nedtrappingsplanen her'}><StopIcon fill="#004d5d" /></span> : ''}
                            {rowsHidden && isLastStep ? <CaretIcon/> : ''}
                        </IconButton>
                    </Box>
                </TableCell>
                <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
                    {formattedDate}
                </TableCell>
                <TableCell colSpan={10} onClick={() => setOpen(!open)}>
                    Oppfølging av behandler
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    {!rowsHidden && !isLastStep &&
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="body1" gutterBottom>
                                    Pasienten trenger nye resepter.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    Pakker som skal forskrives:
                                </Typography>
                                {appointment.packages.map((item, idx) => (
                                    <Typography key={idx} variant="body1" gutterBottom>
                                        {item.brandName} - Styrke: {item.strength} mg, Pakningsstørrelse: {item.packageSize} tabletter, Antall pakker: {item.numPackages}
                                    </Typography>
                                ))}
                            </Box>
                        </Collapse>
                    }
                </TableCell>
            </TableRow>
        </>
    );
}

// -------------------------------------------------------------
// Row Component
// -------------------------------------------------------------
function Row({ step, allDoseTimes, strengths, forceOpen = false, reductionPlan, index, onVisibilityClick, rowsHidden, isLastStep = false }) {

    const [open, setOpen] = useState(false);
    const isOpen = forceOpen || open;

    if (step.rusfri) {
        const rusfriDate = dayjs(step.rusfri.date, 'YYYY-MM-DD', true);
        const formattedDate = rusfriDate.isValid() ? rusfriDate.format('DD.MM.YYYY') : '';
        return (
            <TableRow>
                <TableCell colSpan={2}></TableCell>
                <TableCell colSpan={allDoseTimes.length} sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    Legemiddelfri {formattedDate ? formattedDate : ''}
                </TableCell>
            </TableRow>
        );
    }

    const stepStart = dayjs(step.startDate, 'YYYY-MM-DD', true);
    const stepEnd = dayjs(step.endDate, 'YYYY-MM-DD', true);
    const formattedStartDate = stepStart.isValid() ? stepStart.format('DD.MM') : 'Ugyldig dato';
    const formattedEndDate = stepEnd.isValid() ? stepEnd.format('DD.MM') : 'Ugyldig dato';

    const usedStrengths = strengths.filter(strength =>
        step.dosesPerDay.some(d => d.tablets && d.tablets[strength] > 0)
    );

    var doseTimes = allDoseTimes;

    doseTimes = doseTimes.sort((a, b) => {
        const timeOrder = ['Morgen', 'Formiddag', 'Ettermiddag', 'Middag', 'Kveld'];
        return timeOrder.indexOf(a) - timeOrder.indexOf(b);
    });

    return (
        <>
            <TableRow sx={{ backgroundColor: step.stepNumber % 2 === 0 ? 'inherit' : '#F2F2F2', cursor: 'pointer' }}>
                <TableCell className="compact-xs">
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {isOpen ? <AngleIcon className="up" /> : <AngleIcon className="down" fill="#004d5d" />}
                        </IconButton>
                        <IconButton
                            aria-label="toggle visibility"
                            size="small"
                            onClick={() => onVisibilityClick(index)}
                        >
                            {rowsHidden && isLastStep ? <span title={'Vis resten av nedtrappingsplanen'}><EyeIcon/></span> : !rowsHidden ?
                                <span title={'Stopp nedtrappingsplanen her'}><StopIcon fill="#004d5d" /></span> : ''}
                            {rowsHidden && isLastStep ? <CaretIcon/> : ''}
                        </IconButton>
                    </Box>
                </TableCell>
                <TableCell component="th" scope="row" onClick={() => setOpen(!open)}>
                    Trinn {step.stepNumber}: {formattedStartDate}&#8211;{formattedEndDate}
                </TableCell>
                {doseTimes.map(time => (
                    <TableCell key={time} onClick={() => setOpen(!open)}>
                        {step.dosesPerDay.find(d => d.time === time)?.dose || 0} mg
                    </TableCell>
                ))}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={doseTimes.length + 2}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Doseringsdetaljer:
                            </Typography>
                            <Table size="small" aria-label="prescription-details">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tidspunkt</TableCell>
                                        <TableCell>Dose (mg)</TableCell>
                                        <TableCell>Tablettkombinasjoner</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {doseTimes.map((time, idx) => {
                                        const doseInfo = step.tabletCombination.perDoseTabletInfo.find(d => d.time === time);
                                        const dose = doseInfo ? doseInfo.dose : 0;
                                        const tablets = doseInfo ? doseInfo.tablets : {};
                                        const usedStrengthsAtThisTime = tablets
                                            ? Object.entries(tablets).filter(([_, c]) => c > 0)
                                            : [];

                                        let tabletCombos;
                                        if (usedStrengthsAtThisTime.length > 0) {
                                            tabletCombos = usedStrengthsAtThisTime.map(([s, c]) =>
                                                `${formatTabletCount(c)} x ${s} mg`
                                            ).join(', ');
                                        } else {
                                            const noUsageInEntireStep = !step.dosesPerDay.some(d =>
                                                d.tablets && Object.values(d.tablets).some(cnt => cnt > 0)
                                            );
                                            tabletCombos = noUsageInEntireStep ? '-' : '-';
                                        }

                                        return (
                                            <TableRow key={idx}>
                                                <TableCell component="th" scope="row">
                                                    {time}
                                                </TableCell>
                                                <TableCell>{dose} mg</TableCell>
                                                <TableCell>{tabletCombos}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            {usedStrengths.length > 0 ? (
                                <>
                                    <Typography variant="subtitle1" gutterBottom component="div" sx={{ marginTop: '1rem' }}>
                                        Døgnfordeling:
                                    </Typography>
                                    <Table size="small" aria-label="dose-distribution">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Styrke (mg)</TableCell>
                                                <TableCell>Døgnfordeling</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {usedStrengths.map((strength, idx) => {
                                                const doses = step.dosesPerDay.map(doseInfo => {
                                                    const tabletCount = (doseInfo.tablets && doseInfo.tablets[strength]) || 0;
                                                    return formatTabletCount(tabletCount);
                                                }).join(' - ');
                                                return (
                                                    <TableRow key={idx}>
                                                        <TableCell>{strength} mg</TableCell>
                                                        <TableCell>{doses}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            <TableRow>
                                                <TableCell>Totalt</TableCell>
                                                <TableCell>
                                                    {step.dosesPerDay.map(doseInfo => formatDose(doseInfo.dose)).join(' - ')}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </>
                            ) : (
                                <>
                                    <Typography variant="subtitle1" gutterBottom component="div" sx={{ marginTop: '1rem' }}>
                                        Døgnfordeling:
                                    </Typography>
                                    <p>-</p>
                                </>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

// -------------------------------------------------------------
// Step3 Component
// -------------------------------------------------------------
function Step3({
  setActiveStep,
  pushReplaceState,
  stepTwoCompleted,
  reductionPlan,
  tabletsNeeded,
  smallScreen,
  currentDosage,
  startDate,
  endDate,
  drug,
  // alternativeDrug,
  // originalDrug,
  // calculateAndSetReductionPlan,
  minWeeksBetweenConsultations,
}) {

  const [viewMode, setViewMode] = useState('table');
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccessShort, setCopySuccessShort] = useState(false);
  const [forceExpandAll, setForceExpandAll] = useState(false);
  const [displayedRows, setDisplayedRows] = useState([]);
  const [rowsHidden, setRowsHidden] = useState(false);
  const [consumedPerStrength, setConsumedPerStrength] = useState({});
  const [prescribedPerStrength, setPrescribedPerStrength] = useState({});
  const [strengthsArray, setStrengthsArray] = useState([]);

  // Feedback Modal
  
  const [feedbackModal, setFeedbackModal] = useState(localStorage.getItem('feedbackModal'));

  setTimeout(() => {

    if (feedbackModal !== 'false') {

      setFeedbackModal(false)
      localStorage.setItem('feedbackModal', 'false')
      const popupModal = document.getElementById('popup-modal')
      if (popupModal) popupModal.classList.remove('display-none')

    }

  }, 60000);

  useEffect(() => {

    if (!stepTwoCompleted) {

        setActiveStep(1)
        pushReplaceState(1, true)

    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, []);

  // We'll compute the plan as usual, but store it in displayedRows after merging:
  useEffect(() => {
      const appointmentRows = calculateAppointmentRows(tabletsNeeded, reductionPlan, minWeeksBetweenConsultations);
      let merged = mergeStepsAndAppointments(reductionPlan, appointmentRows);

      // Filter out hidden steps if 'hiddenFromTable' is true:
      merged = merged.filter(row => {
          if (row.type === 'step' && row.step.hiddenFromTable) {
              return false;
          }
          return true;
      });

      setDisplayedRows(merged);
  }, [reductionPlan, tabletsNeeded, minWeeksBetweenConsultations]);

  // -------------------------------------------------------------
  // Every time displayedRows changes, recalc summary for the table
  // -------------------------------------------------------------
  useEffect(() => {
      let consumedTemp = {};
      let prescribedTemp = {};

      displayedRows.forEach(row => {
          if (row.type === 'step' && row.step.stepTotalTablets) {
              // Add to consumed
              for (let [strength, count] of Object.entries(row.step.stepTotalTablets)) {
                  consumedTemp[strength] = (consumedTemp[strength] || 0) + count;
              }
          }
          else if (row.type === 'appointment') {
              // Add to prescribed
              row.appointment.packages.forEach(pkg => {
                  const totalTablets = pkg.packageSize * pkg.numPackages;
                  prescribedTemp[pkg.strength] = (prescribedTemp[pkg.strength] || 0) + totalTablets;
              });
          }
      });

      setConsumedPerStrength(consumedTemp);
      setPrescribedPerStrength(prescribedTemp);

      const allStr = new Set([
          ...Object.keys(consumedTemp),
          ...Object.keys(prescribedTemp)
      ]);
      const sortedStr = Array.from(allStr).sort((a, b) => parseFloat(a) - parseFloat(b));
      setStrengthsArray(sortedStr);

  }, [displayedRows]);

  // -------------------------------------------------------------
  // HANDLER THAT TOGGLES VISIBILITY
  // -------------------------------------------------------------
  const handleVisibilityClick = (index) => {
      if (!rowsHidden) {
          // Hide rows below this index => store them in localStorage
          const removed = displayedRows.slice(index + 1);
          localStorage.setItem('removedRows', JSON.stringify(removed));
          setDisplayedRows(displayedRows.slice(0, index + 1));
      } else {
          // Restore rows from localStorage
          const restored = JSON.parse(localStorage.getItem('removedRows')) || [];
          setDisplayedRows([...displayedRows, ...restored]);
      }
      setRowsHidden(!rowsHidden);
  };

  const allDoseTimesSet = new Set();

  reductionPlan.forEach(step => {
      if (!step.rusfri) {
          step.dosesPerDay.forEach(d => {
              allDoseTimesSet.add(d.time);
          });
      }
  });

  const allDoseTimes = Array.from(allDoseTimesSet).sort();

    function calculateAppointmentRows(tabletsNeeded, reductionPlan, minWeeksBetweenConsultations) {
        let appointmentRows = [];
        let tabletsRemaining = {};

        tabletsNeeded.forEach(pkg => {
            tabletsRemaining[pkg.strength] = 0;
        });

        let currentStepIndex = 0;
        const totalSteps = reductionPlan.length;
        const minDaysBetweenConsultations = minWeeksBetweenConsultations * 7;
        let lastAppointmentDate = null;

        while (currentStepIndex < totalSteps) {
            const step = reductionPlan[currentStepIndex];
            // Skip rusfri step
            if (step.rusfri) {
                currentStepIndex++;
                continue;
            }

            const stepStartDate = dayjs(step.startDate);

            let scheduleAppointment = false;
            if (!lastAppointmentDate) {
                scheduleAppointment = true;
            } else {
                const daysSinceLastAppointment = stepStartDate.diff(lastAppointmentDate, 'day');
                if (daysSinceLastAppointment >= minDaysBetweenConsultations) {
                    scheduleAppointment = true;
                }
            }

            let tabletsNeededUntilNextAppointment = {};
            let daysUntilNextAppointment = 0;

            let i = currentStepIndex;
            while (i < totalSteps && daysUntilNextAppointment < minDaysBetweenConsultations) {
                const futureStep = reductionPlan[i];
                if (futureStep.rusfri || !futureStep.tabletCombination || !futureStep.tabletCombination.perDoseTabletInfo) {
                    if (futureStep.stepDurationDays > 0) {
                        daysUntilNextAppointment += futureStep.stepDurationDays;
                    }
                    i++;
                    continue;
                }

                const overlapDays = futureStep.stepDurationDays;
                daysUntilNextAppointment += overlapDays;

                for (let doseInfo of futureStep.tabletCombination.perDoseTabletInfo) {
                    for (let [strength, count] of Object.entries(doseInfo.tablets)) {
                        const totalCount = count * overlapDays;
                        tabletsNeededUntilNextAppointment[strength] = (tabletsNeededUntilNextAppointment[strength] || 0) + totalCount;
                    }
                }
                i++;
            }

            let tabletsToPrescribe = {};
            for (let [strength, needed] of Object.entries(tabletsNeededUntilNextAppointment)) {
                const remaining = tabletsRemaining[strength] || 0;
                const deficit = needed - remaining;
                if (deficit > 0) {
                    tabletsToPrescribe[strength] = deficit;
                }
            }

            let packagesToPrescribe = [];
            for (let [strength, totalTablets] of Object.entries(tabletsToPrescribe)) {
                const packagesForStrength = tabletsNeeded.filter(pkg => pkg.strength === parseFloat(strength));
                if (packagesForStrength.length === 0) continue;

                // Sort packages in descending order to minimize number of packages prescribed
                packagesForStrength.sort((a, b) => b.packageSize - a.packageSize);
                let tabletsPrescribed = 0;
                for (let pkg of packagesForStrength) {
                    let numPackages = Math.ceil((totalTablets - tabletsPrescribed) / pkg.packageSize);
                    if (numPackages > 0) {
                        packagesToPrescribe.push({
                            ...pkg,
                            numPackages: numPackages
                        });
                        tabletsPrescribed += numPackages * pkg.packageSize;
                    }
                    if (tabletsPrescribed >= totalTablets) break;
                }

                tabletsRemaining[strength] = (tabletsRemaining[strength] || 0) + tabletsPrescribed;
            }

            if (packagesToPrescribe.length > 0 && scheduleAppointment) {
                appointmentRows.push({
                    date: stepStartDate.subtract(1, 'day').format('YYYY-MM-DD'),
                    packages: packagesToPrescribe
                });
                lastAppointmentDate = stepStartDate.subtract(1, 'day');
            }

            for (let [strength, consumed] of Object.entries(tabletsNeededUntilNextAppointment)) {
                tabletsRemaining[strength] -= consumed;
            }

            currentStepIndex = i;
        }

        return appointmentRows;
    }

    function mergeStepsAndAppointments(rp, appts) {
        let stepsWithDate = rp.map(stp => ({
            type: stp.rusfri ? 'rusfri' : 'step',
            date: stp.rusfri ? dayjs(stp.rusfri.date) : dayjs(stp.startDate),
            step: stp
        }));
        let appointmentsWithDate = appts.map(appt => ({
            type: 'appointment',
            date: dayjs(appt.date),
            appointment: appt
        }));

        let merged = [...stepsWithDate, ...appointmentsWithDate];
        merged.sort((a, b) => a.date - b.date);
        return merged;
    }

    function prepareChartData(rp) {
        const dates = [];
        const doses = [];

        rp.forEach(step => {
            if (!step.rusfri) {
                const startValid = dayjs(step.startDate, 'YYYY-MM-DD', true).isValid();
                const endValid = dayjs(step.endDate, 'YYYY-MM-DD', true).isValid();
                if (startValid && endValid) {
                    const dateLabel = `${dayjs(step.startDate).format('DD.MM')}-${dayjs(step.endDate).format('DD.MM')}`;
                    doses.push(step.dose);
                    dates.push(dateLabel);
                }
            }
        });

        return {
            labels: dates,
            datasets: [
                {
                    label: 'Total dose (mg)',
                    data: doses,
                    fill: false,
                    borderColor: 'rgb(75,192,192)',
                    tension: 0.1,
                },
            ],
        };
    }

    /**
     * Generate a text representation of the current plan.
     *
     * Added `rowsHidden` so we can hide prescription text if:
     * - rowsHidden === true
     * - and this row is the LAST row
     * - and row.type === 'appointment'
     */
    function generateTextToCopy(
        mergedRows,
        allDoseTimes,
        drug,
        currentDosage,
        startDate,
        endDate,
        consumedData,
        prescribedData,
        rowsHidden
    ) {
        let text = '';

        text += `Nedtrappingsplan for ${drug.brandName}, utgangsdose ${currentDosage} mg, nedtrappingsvarighet ${calculateDurationInWeeks(startDate, endDate)} uker\n`;
        text += `Startdato: ${dayjs(startDate).format('DD.MM.YYYY')}\n`;
        text += `Sluttdato: ${dayjs(endDate).format('DD.MM.YYYY')}\n\n`;

        mergedRows.forEach((row, i) => {
            const isLastRow = (i === mergedRows.length - 1);

            if (row.type === 'appointment') {
                const appointmentDate = dayjs(row.appointment.date).format('DD.MM.YYYY');
                text += `${appointmentDate}\nAvsjekk og oppfølging av behandler\n`;

                // Hide the prescription part if rows are hidden AND this row is the last row
                if (!(rowsHidden && isLastRow)) {
                    text += `Pasienten trenger nye resepter:\n`;
                    row.appointment.packages.forEach(pkg => {
                        text += `${pkg.brandName} ${pkg.strength} mg, ${pkg.numPackages} pakke(r) med ${pkg.packageSize} tbl\n`;
                    });
                }
                text += `\n`;

            } else if (row.type === 'rusfri') {
                const rusfriDate = dayjs(row.step.rusfri.date).format('DD.MM.YYYY');
                text += `Legemiddelfri ${rusfriDate}\n\n`;

            } else if (row.type === 'step') {
                const step = row.step;
                const stepStart = dayjs(step.startDate, 'YYYY-MM-DD', true);
                const stepEnd = dayjs(step.endDate, 'YYYY-MM-DD', true);
                const startStr = stepStart.isValid() ? stepStart.format('DD.MM') : 'Ugyldig dato';
                const endStr = stepEnd.isValid() ? stepEnd.format('DD.MM') : 'Ugyldig dato';
                text += `Trinn ${step.stepNumber}: ${startStr}&#8211;${endStr}\n`;

                // const times = ['Tidspunkt', ...allDoseTimes];
                // const doses = ['Dose (mg)', ...allDoseTimes.map(time => {
                //     const doseObj = step.dosesPerDay.find(d => d.time === time);
                //     return doseObj ? `${doseObj.dose} mg` : '0 mg';
                // })];

                //const colWidths = times.map((col, i) => Math.max(col.length, (doses[i] || '').length));
                // const headerLine = times.map((col, i) => col.padEnd(colWidths[i])).join('\t');
                // const doseLine = doses.map((col, i) => col.padEnd(colWidths[i])).join('\t');

                text += `\nDoseringsdetaljer:\n`;

                const pdHeader = ['Tidspunkt', 'Dose (mg)', 'Tablettkombinasjoner'];
                const pdRows = allDoseTimes.map(time => {
                    const doseInfo = step.tabletCombination.perDoseTabletInfo.find(d => d.time === time);
                    // it is very important to keep all the following whitespaces so that the raw text formatting is correct
                    const doseVal = doseInfo ? `        ${doseInfo.dose} mg` : '        0 mg';
                    if (doseInfo && doseInfo.tablets) {
                        const usedCombos = Object.entries(doseInfo.tablets).filter(([_, c]) => c > 0);
                        // it is very important to keep all the following whitespaces so that the raw text formatting is correct
                        if (usedCombos.length > 0) {
                            return [
                                time,
                                doseVal,
                                usedCombos.map(([s, c]) => `         ${formatTabletCount(c)} x ${s} mg`).join(', ')
                            ];
                        } else {
                            const noUsageInEntireStep = !step.dosesPerDay.some(d =>
                                d.tablets && Object.values(d.tablets).some(cnt => cnt > 0)
                            );
                            // it is very important to keep all the following whitespaces so that the raw text formatting is correct
                            return [time, doseVal, noUsageInEntireStep ? '         -' : '         -'];
                        }
                    } else {
                        const noUsageInEntireStep = !step.dosesPerDay.some(d =>
                            d.tablets && Object.values(d.tablets).some(cnt => cnt > 0)
                        );
                        // it is very important to keep all the following whitespaces so that the raw text formatting is correct
                        return [time, doseVal, noUsageInEntireStep ? '         -' : '         -'];
                    }
                });

                const allPdRows = [pdHeader, ...pdRows];
                const pdColWidths = [
                    Math.max(...allPdRows.map(r => r[0].length)),
                    Math.max(...allPdRows.map(r => r[1].length)),
                    Math.max(...allPdRows.map(r => r[2].length))
                ];

                const pdHeaderLine = pdHeader.map((c, i) => c.padEnd(pdColWidths[i])).join('\t');
                text += `${pdHeaderLine}\n`;
                pdRows.forEach(r => {
                    const line = r.map((c, i) => c.padEnd(pdColWidths[i])).join('\t');
                    text += `${line}\n`;
                });

                const strengthsUsedInStep = new Set();
                step.tabletCombination.perDoseTabletInfo.forEach(doseInfo => {
                    for (let s in doseInfo.tablets) {
                        if (doseInfo.tablets[s] > 0) strengthsUsedInStep.add(s);
                    }
                });

                // const strengthsArrayStep = Array.from(strengthsUsedInStep).sort((a, b) => parseFloat(b) - parseFloat(a));

                text += `\n`;
            }
        });

        // Append summary of total tablets per strength at the end (based on displayedRows)
        text += `Oppsummering av totalt antall tabletter per styrke\nVurder om apoteket skal dele opp utlevering, føres på resept\n`;
        const summaryHeader = ['Styrke (mg)', 'Antall tabletter forskrevet', 'Antall tabletter konsumert'];
        const allStr = new Set([...Object.keys(consumedData), ...Object.keys(prescribedData)]);
        const sortedStr = Array.from(allStr).sort((a, b) => parseFloat(a) - parseFloat(b));
        const summaryRows = sortedStr.map(strength => {
            const prescribed = prescribedData[strength] || 0;
            const consumed = consumedData[strength] || 0;
            // it is very important to keep all the following whitespaces so that the raw text formatting is correct
            return [`${strength} mg`, `         ${prescribed}`, `                         ${formatTabletCount(consumed)}`];
        });

        const allSummaryRows = [summaryHeader, ...summaryRows];
        const summaryColWidths = [
            Math.max(...allSummaryRows.map(r => r[0].length)),
            Math.max(...allSummaryRows.map(r => r[1].length)),
            Math.max(...allSummaryRows.map(r => r[2].length))
        ];

        const summaryHeaderLine = summaryHeader.map((c, i) => c.padEnd(summaryColWidths[i])).join('\t');
        text += `${summaryHeaderLine}\n`;
        summaryRows.forEach(r => {
            const line = r.map((c, i) => c.padEnd(summaryColWidths[i])).join('');
            text += `${line}\n`;
        });

        return text;
    }

    function generateShortTextToCopy() {
        let text = '';

        const today = dayjs().format('DD.MM');
        const utarbeidetDate = today;

        // Find the first step to get "Dagens dose"
        const firstStep = displayedRows.find(row => row.type === 'step');
        let dagensDose = '';
        if (firstStep) {
            dagensDose = firstStep.step.dosesPerDay
                .sort((a, b) => allDoseTimes.indexOf(a.time) - allDoseTimes.indexOf(b.time))
                .map(d => formatDose(d.dose).replace(' mg', ''))
                .join('+') + 'mg';
        }

        text += `Nedtrappingsplan ${drug.brandName} (${drug.genericName}), utarbeidet ${utarbeidetDate}.\n`;
        text += `Datoer angir første dag i ny dosering.\n\n`;
        text += `Dagens dose: ${dagensDose}\n`;

        displayedRows.forEach(row => {
            if (row.type === 'step') {
                const stepStartDate = dayjs(row.step.startDate).format('DD.MM');
                const doseString = row.step.dosesPerDay
                    .sort((a, b) => allDoseTimes.indexOf(a.time) - allDoseTimes.indexOf(b.time))
                    .map(d => formatDose(d.dose).replace(' mg', ''))
                    .join('+') + 'mg';
                text += `${stepStartDate}: ${doseString}\n`;
            }
        });

        // Find the first rusfri date
        const rusfriRow = displayedRows.find(row => row.type === 'rusfri');
        if (rusfriRow) {
            const rusfriDate = dayjs(rusfriRow.step.rusfri.date).format('DD.MM');
            text += `Første dato uten legemiddel: ${rusfriDate}\n`;
        }

        return text;
    }


    const pdfRef = useRef();
    const chartData = prepareChartData(reductionPlan);

    // ----------------------------------------------------------------
    // Determine finalEndDate based on the last displayed row
    // ----------------------------------------------------------------
    let finalEndDate = endDate;
    if (displayedRows.length > 0) {
        const lastRow = displayedRows[displayedRows.length - 1];
        if (lastRow.type === 'step') {
            finalEndDate = lastRow.step.endDate;
        } else if (lastRow.type === 'appointment') {
            finalEndDate = lastRow.appointment.date;
        }
    }

    // Prepare text to copy using displayedRows, now passing rowsHidden
    const textToCopy = generateTextToCopy(
        displayedRows,
        allDoseTimes,
        drug,
        currentDosage,
        startDate,
        finalEndDate,
        consumedPerStrength,
        prescribedPerStrength,
        rowsHidden
    );

    const handleViewModeChange = (newView) => {
        if (newView !== null) {
            setViewMode(newView);
        }
    };

    const handleCopy = () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    const handleCopyShort = () => {
        setCopySuccessShort(true);
        setTimeout(() => setCopySuccessShort(false), 2000);
    };

    const handleDownloadPDF = () => {
        setForceExpandAll(true);
        setTimeout(() => {
            const element = pdfRef.current;
            const now = dayjs();
            const formattedDateTime = now.format('YYYYMMDD_HHmm');
            const filename = `nedtrappingsplan_${formattedDateTime}.pdf`;
            const opt = {
                margin: [0.5, 0.5, 0.5, 0.5],
                filename: filename,
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 1 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            };
            html2pdf().set(opt).from(element).save().then(() => {
                setForceExpandAll(false);
            });
        }, 500);
    };

    return (
        <Box sx={{ width: '100%', flexGrow: 1 }}>
            <Grid container spacing={3} mt={0}>
                <Grid item xs={12}>
                    <div className="TrappNed-step-icon-title">
                        <StepIcon icon="3" />
                        <div className="TrappNed-step-header">Forslag til nedtrappingsplan</div>
                    </div>
                </Grid>
                <Grid item xs={smallScreen ? 12 : 6}>
                    <Box sx={{display: 'inline-grid'}}>
                        <Typography variant="h5" gutterBottom style={{ maxWidth: '550px' }}>
                            Forslag til poliklinisk nedtrapping med {drug.brandName}, dose {currentDosage} mg over {calculateDurationInWeeks(startDate, finalEndDate)} uker
                        </Typography>
                    </Box>
                </Grid>
                <Grid className="summaryWrapper" item xs={12}>
                    <Box className="summaryBlock" sx={{ textAlign: 'start', display: 'flex', flexDirection: 'row', columnGap: '70px' }}>
                        <Box>
                            <label className="TrappNed-input-label-upper">Varighet</label>
                            <p className="TrappNed-paragraph">{calculateDurationInWeeks(startDate, finalEndDate)} uker</p>
                        </Box>
                        <Box>
                            <label className="TrappNed-input-label-upper">Startdato</label>
                            <p className="TrappNed-paragraph">{dayjs(startDate).format('DD.MM.YYYY')}</p>
                        </Box>
                        <Box>
                            <label className="TrappNed-input-label-upper">Sluttdato</label>
                            <p className="TrappNed-paragraph">{dayjs(finalEndDate).format('DD.MM.YYYY')}</p>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sx={{textAlign: 'center', marginTop: 3}}>
                    <Box
                        className="tableViewButtons"
                        value={viewMode}
                        exclusive
                        aria-label="View mode"
                    >
                        <Typography variant="body1" className="box-label-upper">
                            Vis som
                        </Typography>
                        <div className="tableViewButtonWrapper">
                            <Button
                                aria-label="Table view"
                                className={viewMode === 'table' ? 'tableViewButton active' : 'tableViewButton'}
                                onClick={() => handleViewModeChange('table')}
                            >
                                <CalendarIcon />
                            </Button>
                            Tabell
                        </div>
                        <div className="tableViewButtonWrapper">
                            <Button
                                aria-label="Chart view"
                                className={viewMode === 'chart' ? 'tableViewButton active' : 'tableViewButton'}
                                onClick={() => handleViewModeChange('chart')}
                            >
                                <ChartIcon />
                            </Button>
                            Graf
                        </div>
                    </Box>
                </Grid>

                <Grid item xs={12} ref={pdfRef}>
                    <div>
                        {viewMode === 'table' ? (
                            <>
                                <TableContainer sx={{ border: '1px solid rgba(0, 0, 0, 0.6)', borderRadius: '10px', width: '100%' }}>
                                    <Table size={smallScreen ? 'small' : 'medium'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Periode</TableCell>
                                                {allDoseTimes.map(time => (
                                                    <TableCell key={time}>{time}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {displayedRows.map((row, index) => {
                                                const isLastStep = (displayedRows.length - 1) === index;
                                                if (row.type === 'rusfri') {
                                                    const rusfriDate = dayjs(row.step.rusfri.date).format('DD.MM.YYYY');
                                                    return (
                                                        <TableRow key={`rusfri-${index}`}>
                                                            <TableCell colSpan={2}></TableCell>
                                                            <TableCell colSpan={allDoseTimes.length}>
                                                                Legemiddelfri {rusfriDate}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                } else if (row.type === 'step') {
                                                    return (
                                                        <Row
                                                            key={`step-${row.step.stepNumber}`}
                                                            step={row.step}
                                                            allDoseTimes={allDoseTimes}
                                                            strengths={drug.strengths}
                                                            forceOpen={forceExpandAll}
                                                            reductionPlan={reductionPlan}
                                                            index={index}
                                                            onVisibilityClick={handleVisibilityClick}
                                                            rowsHidden={rowsHidden}
                                                            isLastStep={isLastStep}
                                                        />
                                                    );
                                                } else if (row.type === 'appointment') {
                                                    return (
                                                        <AppointmentRow
                                                            key={`appointment-${index}`}
                                                            appointment={row.appointment}
                                                            forceOpen={forceExpandAll}
                                                            index={index}
                                                            onVisibilityClick={handleVisibilityClick}
                                                            rowsHidden={rowsHidden}
                                                            isLastStep={isLastStep}
                                                        />
                                                    );
                                                }
                                                return null;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {/* Summary of total tablets per strength (based on displayedRows) */}
                                <Grid item xs={12} sx={{ textAlign: 'start', marginTop: '3rem' }}>
                                    <Typography variant="h6" gutterBottom>
                                        Oppsummering av total tabletter per styrke
                                    </Typography>
                                    <TableContainer sx={{ border: '1px solid rgba(0,0,0,0.6)', borderRadius: '10px', width: '100%', marginTop: '1rem' }}>
                                        <Table size={smallScreen ? 'small' : 'medium'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Styrke (mg)</TableCell>
                                                    <TableCell>Antall tabletter forskrevet</TableCell>
                                                    <TableCell>Antall tabletter konsumert</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {strengthsArray.map((strength, idx) => {
                                                    const prescribed = prescribedPerStrength[strength] || 0;
                                                    const consumed = consumedPerStrength[strength] || 0;
                                                    return (
                                                        <TableRow key={idx}>
                                                            <TableCell>{strength} mg</TableCell>
                                                            <TableCell>{prescribed}</TableCell>
                                                            <TableCell>{formatTabletCount(consumed)}</TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        ) : (
                            <Line data={chartData} />
                        )}
                    </div>
                </Grid>
                {viewMode !== 'table' && (
                    <>
                        <Grid item sx={{ marginTop: '10px' }}>
                            <CopyToClipboard text={generateShortTextToCopy()} onCopy={handleCopyShort}>
                                <Button variant="contained" size="small">
                                    Kopier oppsummering til journal{' '}
                                    <CopyIcon sx={{ marginLeft: '0.5rem' }} />
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item sx={{ marginTop: '10px' }}>
                            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                                <Button variant="contained" size="small">
                                    Kopier detaljert plan{' '}
                                    <CopyIcon sx={{ marginLeft: '0.5rem' }} />
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                    </>
                )}
                {viewMode === 'table' && (
                    <>
                        <Grid item sm="auto" sx={{ marginTop: '10px' }}>
                            <CopyToClipboard text={generateShortTextToCopy()} onCopy={handleCopyShort}>
                                <Button variant="contained" size="small">
                                    Kopier oppsummering til journal
                                    <CopyIcon sx={{ marginLeft: '0.5rem' }} />
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                        <Grid item sm="auto" sx={{ marginTop: '10px' }}>
                            <Button variant="contained" size="small" onClick={handleDownloadPDF}>
                                Last ned i utskriftsvennlig format
                                <PDFIcon sx={{ marginLeft: '0.5rem' }} />
                            </Button>
                        </Grid>
                        <Grid item sm="auto" sx={{ marginTop: '10px' }}>
                            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
                                <Button variant="contained" size="small">
                                    Kopier detaljert plan
                                    <CopyIcon sx={{ marginLeft: '0.5rem' }} />
                                </Button>
                            </CopyToClipboard>
                        </Grid>
                    </>
                )}
                {(copySuccess || copySuccessShort) && (
                    <Grid item sx={{ width: '100%' }}>
                        <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                            {copySuccess && "Planen er kopiert til utklippstavlen."}
                            {copySuccessShort && "Kort planen er kopiert til utklippstavlen."}
                        </Typography>
                    </Grid>
                )}

            </Grid>
        </Box>
    );
}

export default Step3;
