import * as React from 'react';
import FormGroup from './FormGroup'
import 'dayjs/locale/nb';
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const FormGroupStyled = styled((props) => (
    <FormGroup {...props} />
))(({ theme }) => ({
    '& .MuiFormControlLabel-root': {
        display: 'flex',
    },
    '& .MuiTypography-root': {
        marginLeft: '0.5rem',
    }
}));

const IOSSwitchStyled = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '& + .MuiSwitch-track': {
            backgroundColor: '#525962',
        },
        '&:not(.Mui-checked)': {
            '&:hover': {
                transform: 'translateX(4px)',
            },
        },

        '&:not(.Mui-checked) .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            color: '#fff',
            position: 'relative',
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: 'none',
                right: 12,
            },
        },
        '&.Mui-checked .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
            color: '#fff',
            position: 'relative',
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#004D5D',
                )}" stroke="${encodeURIComponent(
                    '#004D5D'
                )}" stroke-width="2" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`, // Icon shown when checked
                right: 12,
            },
        },
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#004D5D',
                opacity: 1,
                border: 0,
                ...theme.applyStyles('dark', {
                    backgroundColor: '#2ECA45',
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                backgroundColor: '#525962',
                opacity: 0.5,
            },
            '&:hover': {
                transform: 'translateX(12px)',
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: 'pink',
            ...theme.applyStyles('dark', {
                color: 'yellow',
            }),
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7,
            ...theme.applyStyles('dark', {
                opacity: 0.3,
            }),
        },
    },
    '&.Mui-checked .MuiSwitch-thumb': {
        color: '#fff',
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                '#004D5D',
            )}" stroke="${encodeURIComponent(
                '#004D5D'
            )}" stroke-width="2" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            right: 12,
        },
    },

    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E9E9EA',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
        ...theme.applyStyles('dark', {
            backgroundColor: '#fff',
        }),
    },
}));

export default function IOSSwitch({label, checked, onChange}) {

    return (
        <FormGroupStyled>
            <FormControlLabel
                sx={{margin: 0}}
                label={label}
                control={
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                        <IOSSwitchStyled
                            checked={checked}
                            onChange={(event) => onChange(event.target.checked)}
                        />
                    </Stack>
                }/>
        </FormGroupStyled>
    );
}