import './assets/css/App.css'
import React, { useState, useEffect, useCallback, useMemo, act } from 'react'
import HorizontalStepper from "./Components/HorizontalStepper"
import Button from '@mui/material/Button'
import Step1 from "./Steps/Step1"
import Step2 from "./Steps/Step2"
import Step3 from "./Steps/Step3"
import styled from '@emotion/styled'
import { ReactComponent as ArrowIcon } from "./assets/icons/arrow.svg"
import { useMediaQuery } from "@mui/material"
import { tabletPackages, brands } from './Database/Drugs'
import { calculateReductionPlan } from './Components/utils'
import { StylesProvider } from '@mui/styles'

const ButtonGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ activeStep }) => (activeStep === 3 || activeStep < 1) ? 'end' : 'space-between'};
  align-items: center;
  padding-top: 70px;
  @media (max-width: 768px) {
    width: auto;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 0 1.25rem;
    margin-top: 30px;
    gap: 0.625rem;
  }
`

const backbButtonTexts = [
  'Gå tilbake til preparat og dosering',
  'Gå tilbake til preparat og dosering',
  'Gå tilbake til varighet av plan',
];

const ForwardButtonTexts = [
  'Gå videre til varighet av plan',
  'Gå videre til forslag til plan',
  'Avslutt',
  'Begynn på nytt'
]

function App() {

  const [activeStep, setActiveStep] = useState(0)

  // Step 1 States

  const [drug, setDrug] = useState(null)
  const [currentDosage, setCurrentDosage] = useState('')
  const [currentDuration, setCurrentDuration] = useState('')

  // Step 2 States

  const [startDate, setStartDate] = useState('')
  const [endDateManualOverride, setEndDateManualOverride] = useState(false)
  const [endDate, setEndDate] = useState('')
  const [numberOfDosesPerDay, setNumberOfDosesPerDay] = useState(null)
  // const [numberOfStepsManualOverride, setNumberOfStepsManualOverride] = useState('')
  const [minWeeksBetweenConsultations, setMinWeeksBetweenConsultations] = useState("2")
  const [dosePriority, setDosePriority] = useState('08:00')
  // const [manualOverrideOption, setManualOverrideOption] = useState('')
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [dosesManualOverride, setDosesManualOverride] = useState(false)
  const [dosageStrengthAutomaticOverride, setDosageStrengthAutomaticOverride] = useState(true)

  // Step 3 States

  const [reductionPlan, setReductionPlan] = useState([])
  const [tabletsNeeded, setTabletsNeeded] = useState([])
  const [actualEndDate, setActualEndDate] = useState('')
  const [calculationError, setCalculationError] = useState(null)
  // const [selectedDrug, setSelectedDrug] = useState(null)
  // const [originalDrug, setOriginalDrug] = useState(null)
  const smallScreen = useMediaQuery('(max-width:768px)')

  // Button States

  const activeBackButtonText = backbButtonTexts[activeStep]
  const activeForwardButtonText = ForwardButtonTexts[activeStep]
  const stepOneCompleted = drug && currentDosage && currentDuration
  const stepTwoCompleted = stepOneCompleted && startDate
  const stepThreeCompleted = !calculationError
  const disableForwardButton = activeStep === 0 ? !stepOneCompleted : activeStep === 1 ? !stepTwoCompleted : !stepThreeCompleted

  // const calculateAndSetReductionPlan = useCallback((useOriginalDrug = false) => {
  const calculateAndSetReductionPlan = useCallback(() => {

    // const drugToUse = useOriginalDrug ? originalDrug : drug

    // if (!drugToUse) {

    //   setCalculationError('Ingen legemiddel valgt')
    //   return
      
    // }

    // const brandId = drugToUse.brandId
    
    const brandId = drug.brandId

    try {

      //const { doses, tabletsNeeded, actualEndDate, drug: calcSelectedDrug } = calculateReductionPlan({
      const { doses, tabletsNeeded, actualEndDate/*, drug*/ } = calculateReductionPlan({
        brandId,
        currentDosage,
        currentDuration,
        startDate,
        endDateManualOverride,
        endDate,
        numberOfDosesPerDay,
        dosePriority,
        // numberOfStepsManualOverride,
        // manualOverrideOption,
        selectedStrengths,
        dosageStrengthAutomaticOverride,
        minWeeksBetweenConsultations,
        // useOriginalDrug
      })

      setReductionPlan(doses)
      setTabletsNeeded(tabletsNeeded)
      setActualEndDate(actualEndDate)
      // setSelectedDrug(calcSelectedDrug)
      setCalculationError(null)

      // if (!originalDrug) setOriginalDrug(drug)

    } catch (error) {

      setCalculationError(error.message)
      setReductionPlan([])
      setTabletsNeeded([])
      setActualEndDate('')
      // setSelectedDrug(null)

    }

  }, [
    drug,
    // originalDrug,
    currentDosage,
    currentDuration,
    startDate,
    endDateManualOverride,
    endDate,
    numberOfDosesPerDay,
    dosePriority,
    // numberOfStepsManualOverride,
    // manualOverrideOption,
    selectedStrengths,
    dosageStrengthAutomaticOverride,
    minWeeksBetweenConsultations,
  ])

  // useEffect(() => {

  //   setOriginalDrug(null)

  // }, [drug])

  useEffect(() => {

    if (activeStep === 0) {

      const urlParams = new URLSearchParams(window.location.search)
      window.history.replaceState({}, document.title, window.location.pathname)

    }

    if (startDate) calculateAndSetReductionPlan()

  }, [startDate, calculateAndSetReductionPlan])

  const startOver = () => {

    setActiveStep(0)
    setDrug(null)
    setCurrentDosage('')
    setCurrentDuration('')
    setStartDate('')
    setEndDateManualOverride(false)
    setEndDate('')
    setNumberOfDosesPerDay(null)
    setReductionPlan([])
    // setManualOverrideOption('')
    // setNumberOfStepsManualOverride('')
    setCalculationError(null)
    setTabletsNeeded([])
    setDosePriority('08:00')
    setActualEndDate('')
    setSelectedStrengths([])
    // setSelectedDrug(null)
    // setOriginalDrug(null)
    setDosesManualOverride(false)
    setDosageStrengthAutomaticOverride(true)
    setMinWeeksBetweenConsultations("2")

  }

  const handleNext = () => {

    if (activeStep < 3) {

      if (activeStep === 1) calculateAndSetReductionPlan()

      let newStep = activeStep + 1

      setActiveStep(newStep)
      pushReplaceState(newStep)
        
    }

  }

  const handleBack = () => {

    if (activeStep > 0) {

      let newStep = activeStep - 1
      
      setActiveStep(newStep)
      pushReplaceState(newStep)

    }

  }

  const handleStepChange = (newStep) => {

    setActiveStep(newStep)
    pushReplaceState(newStep)

  }

  const pushReplaceState = (step, replace = false) => {

    let newUrl

    if (step > 0) {

      const params = new URLSearchParams(window.location.search)
      params.set('step', step + 1)
      newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${params.toString()}`

    } else {

      newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

    }

    if (replace) window.history.replaceState({ path: newUrl }, '', newUrl)
    else window.history.pushState({ path: newUrl }, '', newUrl)

  }

  window.onpopstate = () => {

    const urlParams = new URLSearchParams(window.location.search)
    
    let step = urlParams.get('step')

    if (step) step = parseInt(step) - 1
    else step = 0

    setActiveStep(step)

  }

  const uniqueDrugs = useMemo(() => {

    const uniqueDrugMap = {}

    tabletPackages.forEach(pkg => {

      const brand = brands.find(b => b.brandId === pkg.brandId);

      if (!uniqueDrugMap[pkg.brandId]) {
          uniqueDrugMap[pkg.brandId] = {
              name: brand.brandName,
              genericName: brand.genericName,
              drugType: brand.drugType,
              strengths: [],
              divisible: pkg.divisible,
              brandId: pkg.brandId,
              timesPerDay: brand.timesPerDay,
              brandName: brand.brandName,
          };
      }

      if (!uniqueDrugMap[pkg.brandId].strengths.includes(pkg.strength)) {
          uniqueDrugMap[pkg.brandId].strengths.push(pkg.strength);
      }

    })

    return Object.values(uniqueDrugMap)

  }, [])



  // const handleRecalculateWithOriginalDrug = () => {

  //     if (originalDrug) calculateAndSetReductionPlan(true)

  // }

  return (

    <StylesProvider injectFirst>
      <div className="TrappNed-App">
        <header className="TrappNed-navigation">
          <HorizontalStepper activeStep={activeStep} smallscreen={smallScreen.toString()} handleStepChange={handleStepChange} />
        </header>
        <div className="TrappNed-main-content">
          {activeStep === 0 && (
            <Step1
              drug={drug}
              setDrug={setDrug}
              currentDosage={currentDosage}
              setCurrentDosage={setCurrentDosage}
              currentDuration={currentDuration}
              setCurrentDuration={setCurrentDuration}
              smallScreen={smallScreen}
              uniqueDrugs={uniqueDrugs}
              //drugType={selectedDrug?.drugType.toLowerCase().includes('benzodiazepiner') ? 'benzodiazepiner' : 'opioder'}
              drugType={drug?.drugType.toLowerCase().includes('benzodiazepiner') ? 'benzodiazepiner' : 'opioder'}
            />
          )}
          {activeStep === 1 && (
            <Step2
              setActiveStep={setActiveStep}
              pushReplaceState={pushReplaceState}
              stepOneCompleted={stepOneCompleted}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              endDateManualOverride={endDateManualOverride}
              setEndDateManualOverride={setEndDateManualOverride}
              numberOfDosesPerDay={numberOfDosesPerDay}
              setNumberOfDosesPerDay={setNumberOfDosesPerDay}
              // numberOfStepsManualOverride={numberOfStepsManualOverride}
              // setNumberOfStepsManualOverride={setNumberOfStepsManualOverride}
              dosePriority={dosePriority}
              setDosePriority={setDosePriority}
              smallScreen={smallScreen}
              // manualOverrideOption={manualOverrideOption}
              // setManualOverrideOption={setManualOverrideOption}
              // drug={selectedDrug || drug}
              drug={drug}
              selectedStrengths={selectedStrengths}
              setSelectedStrengths={setSelectedStrengths}
              actualEndDate={actualEndDate}
              dosesManualOverride={dosesManualOverride}
              setDosesManualOverride={setDosesManualOverride}
              dosageStrengthAutomaticOverride={dosageStrengthAutomaticOverride}
              setDosageStrengthAutomaticOverride={setDosageStrengthAutomaticOverride}
              minWeeksBetweenConsultations={minWeeksBetweenConsultations}
              setMinWeeksBetweenConsultations={setMinWeeksBetweenConsultations}
            />
          )}
          {(activeStep === 2 || activeStep === 3) && !calculationError && (
            <>
            
              {/* {selectedDrug && originalDrug && selectedDrug.brandId !== originalDrug?.brandId && (
                <div class="alertBox">
                  <p>Du valgte <strong>{originalDrug.name}</strong>, men kalkulatoren har valgt <strong>{selectedDrug.brandName}</strong> som et bedre alternativ.</p>
                  <p>{selectedDrug.brandName} er valgt fordi det gir en mer optimal doseringsplan basert på tilgjengelige styrker og pakningsstørrelser.</p>
                  <Button variant="outlined" onClick={() => handleRecalculateWithOriginalDrug()}>
                      Bruk opprinnelig valgte preparat
                  </Button>
                </div>
              )} */}

              <Step3
                setActiveStep={setActiveStep}
                pushReplaceState={pushReplaceState}
                stepTwoCompleted={stepTwoCompleted}
                reductionPlan={reductionPlan}
                tabletsNeeded={tabletsNeeded}
                smallScreen={smallScreen}
                // drug={selectedDrug || drug}
                drug={drug}
                currentDosage={currentDosage}
                startDate={startDate}
                endDate={actualEndDate}
                numberOfDosesPerDay={numberOfDosesPerDay}
                // alternativeDrug={selectedDrug && selectedDrug.brandId !== originalDrug?.brandId}
                // originalDrug={originalDrug}
                calculateAndSetReductionPlan={calculateAndSetReductionPlan}
                minWeeksBetweenConsultations={minWeeksBetweenConsultations}
              />
            </>
          )}
          {activeStep === 2 && calculationError && (
            <div className="errorMessage">
              {calculationError}
            </div>
          )}
          <ButtonGroup activeStep={activeStep} smallScreen={smallScreen}>
            {activeStep > 0 && activeStep < 3 && (
              <Button
                  className="arrow-back"
                  variant="contained"
                  onClick={handleBack}
              >
                <ArrowIcon />
                {activeBackButtonText}
              </Button>
            )}
            {activeStep < 3 && (
              <Button
                className={activeStep === 2 ? 'wide' : 'arrow-forward'}
                variant="contained"
                disabled={disableForwardButton}
                onClick={handleNext}
              >
                {activeForwardButtonText}
                {activeStep !== 2 ? <ArrowIcon /> : false}
              </Button>
            )}
            {activeStep === 3 && (
              <Button
                className="arrow-forward"
                variant="contained"
                onClick={startOver}
              >
                <ArrowIcon />
                {activeForwardButtonText}
              </Button>
            )}
          </ButtonGroup>
        </div>
      </div>
    </StylesProvider>

  )

}

export default App
