import * as React from 'react'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/nb'

dayjs.extend(utc)

export default function DatePicker({ value, onChange, hasError }) {

  const handleDateChange = (newValue) => {
      
    let utcDate = newValue ? dayjs(newValue).utc() : null
    if (utcDate) utcDate = utcDate.utcOffset(120)
    
    onChange(utcDate)

  }

  return (

    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale="nb"
      localeText={{
        fieldMonthPlaceholder: (params) => 'mm',
        fieldDayPlaceholder: (params) => 'dd',
        fieldYearPlaceholder: (params) => 'åååå'
      }}
    >
      <MuiDatePicker
        className="datePicker"
        value={value ? dayjs(value) : null}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} error={hasError} />}
      />
    </LocalizationProvider>
    
  )

}
