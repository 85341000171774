import React, { useEffect, useState } from 'react'
import { Grid, TextField, FormControlLabel, RadioGroup, Radio, FormControl, Checkbox, FormGroup, Box, StepIcon} from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import IOSSwitch from "../Components/IOSSwitch"
import DatePicker from "../Components/DatePicker"
import dayjs from "dayjs"
import { calculateDurationInWeeks } from "../Components/utils"

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            backgroundColor: 'white',
            border:'none'
          }
        }
      }
    }
  }
})

function getDoseTimes(dosesPerDay) {

  const timesByDoseCount = {
    1: ['Morgen'],
    2: ['Morgen', 'Kveld'],
    3: ['Morgen', 'Middag', 'Kveld'],
    4: ['Morgen', 'Formiddag', 'Ettermiddag', 'Kveld']
  }

  return timesByDoseCount[dosesPerDay] || ['Morgen']

}

function Step2({
  setActiveStep,
  pushReplaceState,
  stepOneCompleted,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  endDateManualOverride,
  setEndDateManualOverride,
  numberOfDosesPerDay,
  setNumberOfDosesPerDay,
  dosesManualOverride,
  setDosesManualOverride,
  dosageStrengthAutomaticOverride,
  setDosageStrengthAutomaticOverride,
  // numberOfStepsManualOverride,
  // setNumberOfStepsManualOverride,
  dosePriority,
  setDosePriority,
  smallScreen,
  // manualOverrideOption,
  // setManualOverrideOption,
  drug,
  selectedStrengths,
  setSelectedStrengths,
  actualEndDate,
  minWeeksBetweenConsultations,
  setMinWeeksBetweenConsultations
}) {

  // const [overrideOption, setOverrideOption] = useState(manualOverrideOption)
  const [hasErrorEndDate, setHasErrorEndDate] = useState(false)
  const [hasErrorStartDate, setHasErrorStartDate] = useState(false)
  const [availableDoseTimes, setAvailableDoseTimes] = useState([])
  const [preferredTime, setPreferredTime] = useState(dosePriority || 'Morgen')
  const [chooseChosen, setChooseChosen] = useState('Velg')

  useEffect(() => {

    if (!stepOneCompleted) {

      setActiveStep(0)
      pushReplaceState(0, true)
      
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    if (!drug) {

      setAvailableDoseTimes([])
      return

    }

    const doses = dosesManualOverride ? parseInt(numberOfDosesPerDay) || 1 : drug.timesPerDay || 1

    const times = getDoseTimes(doses)
    setAvailableDoseTimes(times)

    if (!times.includes(preferredTime)) {

      setPreferredTime(times[0])
      setDosePriority(times[0])

    }

    setDosePriority(preferredTime)

  }, [numberOfDosesPerDay, drug, dosesManualOverride, preferredTime, setDosePriority])

  const handlePreferredTimeChange = (event) => {

    const value = event.target.value
    setPreferredTime(value)
    setDosePriority(value)

  }

  // useEffect(() => {

  //   setDosePriority(preferredTime)

  // }, [preferredTime, setDosePriority])

  // const handleOverrideOptionChange = (event) => {

  //   const value = event.target.value
  //   setOverrideOption(value)
  //   setManualOverrideOption(value)

  //   if (value === 'endDate') setNumberOfStepsManualOverride('')
  //   else if (value === 'numberOfSteps') setEndDate('')

  // }

  const validateEndDate = (newDate) => {

    const formatDayjs = dayjs(newDate)

    if (formatDayjs.isValid()) {

      setEndDate(newDate)
      setHasErrorEndDate(false)

    } else {

      setHasErrorEndDate(true)

    }

  }

  const validateStartDate = (newDate) => {

    const formatDayjs = dayjs(newDate)
    const formattedDate = formatDayjs.format('YYYY-MM-DD')

    if (formatDayjs.isValid() && formattedDate) {

      setStartDate(formattedDate)
      setHasErrorStartDate(false)
      setChooseChosen('Valgt')

    } else {

      setStartDate(false)
      setHasErrorStartDate(true)
      setChooseChosen('Velg')

    }

  }

  return (

    <Box sx={{ width: '100%', flexGrow: 1 }}>
      <Grid container spacing={3} mt={0}>
        <Grid item xs={12}>
          <div className="TrappNed-step-icon-title">
            <StepIcon icon="2" />
            <div className="TrappNed-step-header">Varighet av nedtrappingsplan</div>
          </div>
        </Grid>
        <Grid item xs={smallScreen ? 12 : 6}>
          <Box sx={{display: 'inline-grid'}}>
            <label className="TrappNed-small-title">Startdato</label>
            <p className="TrappNed-small-description">{chooseChosen} dato for oppstart av nedtrappingsplanen</p>
            <ThemeProvider theme={theme}>
              <DatePicker
                value={startDate}
                onChange={(date) => validateStartDate(date)}
                hasError={hasErrorStartDate}
                sx={{
                  '& .MuiInputBase-root': {
                    input: {
                      backgroundColor: '#fff'
                    }
                  }
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
          <Grid item xs={smallScreen ? 12 : 6}>
            <Grid item xs={12}>
              <label className="TrappNed-small-title">Anbefalt varighet</label>
              <p className="TrappNed-small-description">Her vises det anbefalte tidsintervallet for nedtrappingsplanen basert på dine valg. Om du velger manuell overstyring gir dette mulighet til å påvirke nedtrappingsplanen.</p>
            </Grid>
              {startDate && (
                <>
                  <Grid size={smallScreen ? 12 : 6}>
                    <label className="TrappNed-input-label-upper">Varighet</label>
                    <p className="TrappNed-paragraph">{startDate && actualEndDate ? calculateDurationInWeeks(startDate, actualEndDate) + ' uker' : ''}</p>
                  </Grid>
                  <Grid size={smallScreen ? 12 : 6}>
                    <label className="TrappNed-input-label-upper">Startdato</label>
                    <p className="TrappNed-paragraph">{startDate ? dayjs(startDate).format('DD.MM.YYYY') : ''}</p>
                  </Grid>
                  <Grid size={smallScreen ? 12 : 6}>
                    <label className="TrappNed-input-label-upper">Sluttdato</label>
                    <p className="TrappNed-paragraph">{actualEndDate ? dayjs(actualEndDate).utc().format('DD.MM.YYYY') : ''}</p>
                  </Grid>
                  <Grid item xs={12}>
                    <IOSSwitch
                      label={endDateManualOverride ? 'Manuell overstyring er slått på' : 'Slå på manuell overstyring'}
                      checked={endDateManualOverride}
                      name="endDateManualOverride"
                      onChange={(checked) => {
                        setEndDateManualOverride(checked)
                        if (!checked) {
                          // setManualOverrideOption('')
                          setEndDate(undefined)
                          // setNumberOfStepsManualOverride('')
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
              {/* {endDateManualOverride && (
                <Grid item xs={12}>
                  <FormControl>
                    <p className="TrappNed-input-label-upper">Velg manuelt overstyringsalternativ</p>
                    <RadioGroup
                      row
                      value={overrideOption}
                      onChange={handleOverrideOptionChange}
                      sx={{ flexDirection: smallScreen ? 'column' : 'row' }}
                    >
                      <FormControlLabel
                        value="endDate"
                        control={<Radio />}
                        label="Manuell sluttdato"
                      />
                      <FormControlLabel
                        value="numberOfSteps"
                        control={<Radio />}
                        label="Manuelt antall trinn"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              )} */}
              {endDateManualOverride && ( // && overrideOption === 'endDate'
                <Grid item xs={smallScreen ? 12 : 8}>
                  <Box sx={{textAlign: 'start'}}>
                    <label className="TrappNed-small-title">Sluttdato</label>
                    <p className="TrappNed-small-description">Velg sluttdato for nedtrappingsplanen</p>
                    <ThemeProvider theme={theme}>
                      <DatePicker
                        value={endDate || null}
                        onChange={(date) => validateEndDate(date)}
                        hasError={hasErrorEndDate}
                      />
                    </ThemeProvider>
                    <p className="TrappNed-paragraph-light-grey">Kalkulatoren vil lage en gyldig nedtrappingsplan med sluttdato nærmest mulig den valgte dato</p>
                  </Box>
                </Grid>
              )}
              {/* {endDateManualOverride && overrideOption === 'numberOfSteps' && (
                <Grid item xs={smallScreen ? 12 : 8} sx={{ width: '100%' }}>
                  <Box sx={{textAlign: 'start'}}>
                    <label className="TrappNed-small-title">Antall nedtrappingstrinn</label>
                    <p className="TrappNed-small-description">Her legges inn antall trinn som ønskes</p>
                    <TextField
                      value={numberOfStepsManualOverride}
                      onChange={e => setNumberOfStepsManualOverride(e.target.value)}
                      placeholder={"Antall trinn"}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          input: {
                            backgroundColor: '#fff'
                          }
                        }
                      }}
                      error={false}
                      type="text"
                      helperText={''}
                    />
                    <p className="TrappNed-paragraph-light-grey">Kalkulatoren vil lage en gyldig nedtrappingsplan med antall trinn nærmest mulig det foretrukne</p>
                  </Box>
                </Grid>
              )} */}
          </Grid>
          {startDate && (
            <Grid item xs={12}>
              <FormControl sx={{width: '100%', marginTop: '46px'}}>
                <label id="row-radio-buttons-group-label-doses" className="TrappNed-small-title" style={{textAlign: 'start'}}>Antall doser per døgn</label>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={numberOfDosesPerDay}
                  onChange={(event) => setNumberOfDosesPerDay(event.target.value)}
                  sx={{flexDirection: smallScreen ? 'column' : 'row'}}
                >
                  <div className={'TrappNed-radio-group-dosage'}>
                    <FormControlLabel
                      value="1"
                      control={<Radio/>}
                      label="En dose"
                      disabled={!dosesManualOverride}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio/>}
                      label="To doser"
                      disabled={!dosesManualOverride}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio/>}
                      label="Tre doser"
                      disabled={!dosesManualOverride}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio/>}
                      label="Fire doser"
                      disabled={!dosesManualOverride}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
              <FormGroup>
                <IOSSwitch
                  label={dosesManualOverride ? 'Manuell overstyring er slått på' : 'Slå på manuell overstyring'}
                  checked={dosesManualOverride}
                  name="dosesManualOverride"
                  onChange={(checked) => {
                    setDosesManualOverride(checked)
                    if (!checked) {
                      setNumberOfDosesPerDay(drug.timesPerDay || 1)
                      setPreferredTime('Morgen')
                      setDosePriority('Morgen')
                    }
                  }}
                />
              </FormGroup>
            </Grid>
          )}
          {dosesManualOverride && numberOfDosesPerDay && startDate && (
            <Grid item xs={12} sx={{ textAlign: 'start' }}>
              <FormControl sx={{width: '100%'}}>
                <label id="row-radio-buttons-group-label-doses" className="TrappNed-small-title" style={{textAlign: 'start'}}>Døgnfordeling</label>
                <p className="TrappNed-small-description" style={{textAlign: 'start', marginBottom: '0.5rem'}}>Velg et tidspunkt som skal prioriteres for dosering</p>
                <RadioGroup
                  row
                  aria-labelledby="row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={preferredTime}
                  onChange={handlePreferredTimeChange}
                  sx={{flexDirection: smallScreen ? 'column' : 'row'}}
                >
                  <div className={'TrappNed-radio-group-dosage'}>
                    {availableDoseTimes.map((time, index) => (
                      <FormControlLabel
                        key={time}
                        value={time}
                        control={<Radio/>}
                        label={`${time}`}
                      />
                    ))}
                  </div>
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {startDate && (
            <>
              <Grid item xs={12} sx={{ textAlign: 'start', marginTop: '46px' }}>
                <FormControl sx={{width: '100%'}}>
                  <label id="row-radio-buttons-group-label-doses" className="TrappNed-small-title">Bruk ulike styrker av samme preparat</label>
                  <p className="TrappNed-small-description" style={{textAlign: 'start', marginBottom: '0.5rem'}}>Tillat kun bruk av disse styrkene</p>
                  <FormGroup
                    sx={{flexDirection: smallScreen ? 'column' : 'row' }}
                    className={'TrappNed-checkbox-formgroup'}
                  >
                    {drug && drug.strengths.map((strength) => (
                      <FormControlLabel
                        key={strength}
                        control={
                          <Checkbox
                            name={strength.toString()}
                            checked={selectedStrengths.includes(strength)}
                            onChange={(e) => {
                              const value = parseFloat(e.target.name)
                              if (e.target.checked) setSelectedStrengths([...selectedStrengths, value])
                              else setSelectedStrengths(selectedStrengths.filter((s) => s !== value))
                            }}
                            disabled={dosageStrengthAutomaticOverride}
                          />
                        }
                        label={`${strength} mg`}
                      />
                    ))}
                  </FormGroup>
                  <FormGroup sx={{mt:3}}>
                    <IOSSwitch
                      label={dosageStrengthAutomaticOverride ? 'Automatisk justering av styrker er slått på' : 'Sett på automatisk justering av styrker'}
                      checked={dosageStrengthAutomaticOverride}
                      name="dosageStrengthAutomaticOverride"
                      onChange={(checked) => {
                        setDosageStrengthAutomaticOverride(checked)
                        if (checked) setSelectedStrengths([])
                      }}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={smallScreen ? 12 : 6} sx={{width: '100%', marginTop: '46px'}}>
                <div style={{textAlign: 'start'}}>
                  <label className="TrappNed-small-title">Minimum uker mellom konsultasoner</label>
                  <p className="TrappNed-small-description" style={{marginBottom: '0.5rem'}}>Hvor ofte skal pasienten komme på kontroll?</p>
                  <Box sx={{ display: 'grid', gridAutoFlow: 'column', columnGap: '10px', justifyContent: 'start', alignItems: 'center', marginTop: '25px' }}>
                    Hver
                    <TextField
                      value={minWeeksBetweenConsultations}
                      onChange={e => setMinWeeksBetweenConsultations(e.target.value)}
                      placeholder={"Min antall uker"}
                      sx={{
                        maxWidth: '100px', 
                        width: '100%',
                        '& .MuiInputBase-root': {
                          marginTop: '0',
                          input: {
                            backgroundColor: '#fff'
                          }
                        }
                      }}
                      error={false}
                      type="text"
                      helperText={''}
                    />
                    uke
                  </Box>
                </div>
              </Grid>
            </>
          )}
      </Grid>
    </Box>
  )

}

export default Step2
